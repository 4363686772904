import React, { FC } from 'react';

export const SearchCloseIcon: FC = () => {
    return (
        <svg
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M17.1629 2.16291C17.529 1.7968 17.529 1.2032 17.1629 0.837087C16.7968 0.470971 16.2032 0.470971 15.8371 0.837087L9 7.67417L2.16291 0.837087C1.7968 0.470971 1.2032 0.470971 0.837087 0.837087C0.470971 1.2032 0.470971 1.7968 0.837087 2.16291L7.67417 9L0.837087 15.8371C0.470971 16.2032 0.470971 16.7968 0.837087 17.1629C1.2032 17.529 1.7968 17.529 2.16291 17.1629L9 10.3258L15.8371 17.1629C16.2032 17.529 16.7968 17.529 17.1629 17.1629C17.529 16.7968 17.529 16.2032 17.1629 15.8371L10.3258 9L17.1629 2.16291Z'
                fill='currentColor'
            />
        </svg>
    );
};
