import { useCallback, useEffect, useState } from 'react';
import { logger } from '@shared-services/log-service';

export enum SNIPCART_EVENTS {
    SNIPCART_READY = 'snipcart.ready',
    SNIPCART_INITIALIZED = 'snipcart.initialized',
    ITEM_ADDED = 'item.added',
    ITEM_UPDATED = 'item.updated',
    ITEM_REMOVED = 'item.removed',
    CART_CONFIRMED = 'cart.confirmed',
}

export const updateCartEvents = [
    SNIPCART_EVENTS.SNIPCART_INITIALIZED,
    SNIPCART_EVENTS.ITEM_ADDED,
    SNIPCART_EVENTS.ITEM_UPDATED,
    SNIPCART_EVENTS.ITEM_REMOVED,
    SNIPCART_EVENTS.CART_CONFIRMED,
] as const;

const useSnipcartApi = () => {
    const [itemsCount, setItemsCount] = useState(0);

    useEffect(() => {
        const snipcartCartEventListeners: (() => void)[] = [];

        if (typeof window === 'undefined') {
            return;
        }

        if (window.Snipcart) {
            bindSnipcartListeners();
            updateCartCount();
        } else {
            window.document.addEventListener(
                SNIPCART_EVENTS.SNIPCART_READY,
                bindSnipcartListeners
            );
        }

        function bindSnipcartListeners() {
            try {
                updateCartEvents.forEach((snipcartEvent) => {
                    const unsubscribeEvent = window.Snipcart?.events.on(
                        snipcartEvent,
                        updateCartCount
                    );
                    unsubscribeEvent &&
                        snipcartCartEventListeners.push(unsubscribeEvent);
                });
            } catch (err) {
                logger.error('cannot bind snipcart listeners: ' + err);
            }
        }

        function updateCartCount() {
            try {
                const cartItemsCount =
                    window.Snipcart?.store.getState().cart.items.count ?? 0;
                setItemsCount(cartItemsCount);
            } catch (err) {
                logger.error('cannot update cart count: ' + err);
                setItemsCount(-1);
            }
        }

        return () => {
            snipcartCartEventListeners.forEach((unsubscribeEvent) => {
                if (
                    unsubscribeEvent &&
                    typeof unsubscribeEvent === 'function'
                ) {
                    unsubscribeEvent();
                }
            });
        };
    }, []);

    const openSnipcart = useCallback(() => {
        window.Snipcart?.api.theme.cart.open();
    }, []);

    return { itemsCount, openSnipcart };
};

export default useSnipcartApi;
