import { getWindowInBrowser } from '../../RuntimeWindow';
import { useCallback } from 'react';

export const useGetItemUrl = () => {
    const windowInBrowser = getWindowInBrowser();
    const isPublished = isPublishedSite();
    const siteAlias = windowInBrowser?.Parameters?.SiteAlias;

    return useCallback(
        ({
            collectionPath,
            itemUrl,
        }: {
            collectionPath: string;
            itemUrl: string;
        }) => {
            const collectionItemUrl = getMultiLangUrl(
                `${collectionPath}/${itemUrl}`
            );
            if (isPublished) {
                return `/${collectionItemUrl}`;
            }
            return `/site/${siteAlias}/${collectionItemUrl}${document?.location?.search}`;
        },
        [isPublished, siteAlias]
    );
};

function isPublishedSite() {
    return getWindowInBrowser()?.dmAPI?.getCurrentEnvironment?.() === 'live';
}

const getMultiLangUrl = (url: string) => {
    const windowInBrowser = getWindowInBrowser();
    const defaultSiteLang = windowInBrowser?.Parameters?.defaultLang;
    const targetLang =
        windowInBrowser?.Parameters?.IsSiteMultilingual &&
        windowInBrowser?.dmAPI.getSiteCurrentLocale();
    if (targetLang && defaultSiteLang && targetLang !== defaultSiteLang) {
        return `${targetLang}/${url}`;
    }
    return url;
};
