import React from 'react';
import { WidgetStyles } from './types/styles';
import { MenuPanelMode } from './types';

export interface FSContextType {
    styles: WidgetStyles;
    menuPanelMode?: MenuPanelMode;
    inDesignMode?: boolean;
    hasProducts?: boolean;
    isPremiumWidget?: boolean;
    currentPageType?: string;
}
export const FilterSortContext = React.createContext<FSContextType>({
    styles: {},
});

export function useFilterSortStyles() {
    return useFilterSortContext().styles;
}
export function useFilterSortContext() {
    return React.useContext(FilterSortContext);
}
