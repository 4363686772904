import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { toCSSObject } from 'client/widget-components/responsive-styles/toCSSObject';
import type { TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';

export interface TextareaProps
    extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    styles?: ResponsiveStylesProp;
    increaseSpecificity?: boolean;
}

const Textarea = styled.textarea<TextareaProps>(
    ({ styles, increaseSpecificity }) =>
        toCSSObject([resetStyles, styles], increaseSpecificity)
);

const resetStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        padding: 'unset',
        margin: 'unset',
        backgroundColor: 'unset',
        border: '1px solid #000000',
        color: '#000000',
        minHeight: 'unset',
        height: 'unset',
        fontSize: 'unset',
        resize: 'none',
    },
};

export default Textarea;
