import { postToEditor } from 'client/editorMsApi/postToEditor';
import { configure, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import propsStore, { WidgetStore } from '../stores/PropsStore';
import { EditorStateContext } from './editorState';

configure({ isolateGlobalState: true });

interface ObserverWidgetProps {
    Component: React.FC<any>;
    widgetId: string;
}

function ObserverWidget({ Component, widgetId }: ObserverWidgetProps) {
    const widgetStore: WidgetStore = propsStore.getWidgetStore(widgetId);

    useEffect(() => {
        postToEditor({
            type: 'widget-rendered-following-props-change',
            widgetId,
        });
    }, [widgetStore.props]);

    const { key, ...props } = toJS(widgetStore.props);
    return (
        <EditorStateContext>
            <Component widgetId={widgetId} key={key} {...props} />
        </EditorStateContext>
    );
}

export default observer(ObserverWidget);
