import React, { FC } from 'react';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import { localizationService } from '@shared-services/localization-service';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { Div } from 'client/widget-components/basicComponents';
import { Collection } from '../types';
import { SearchGroup } from './SearchGroup';
import { HiddenItemForEditorComputedStyles } from './HiddenItemForEditorComputedStyles';

interface SearchDropdownProps {
    collections: Collection[];
    styles?: {
        dropdown?: ResponsiveStylesProp;
        dropdownItemHover?: ResponsiveStylesProp;
    };
    isResultsShown?: boolean;
    isLoading?: boolean;
    fetchMore: (name: string) => void;
}

export const SearchDropdown: FC<SearchDropdownProps> = ({
    collections,
    styles,
    isResultsShown,
    isLoading,
    fetchMore,
}) => {
    const collectionLength = collections.reduce((res, collection) => {
        return res + collection.items.length;
    }, 0);

    return (
        <Div
            styles={[dropdownStyles, styles?.dropdown]}
            data-grab='collection-search-widget-dropdown'
        >
            {isResultsShown && (
                <>
                    {collectionLength > 0 &&
                        collections.map((collection) => (
                            <SearchGroup
                                key={collection.name}
                                collection={collection}
                                styles={styles}
                                fetchMore={() => {
                                    fetchMore(collection.name);
                                }}
                            />
                        ))}

                    {collectionLength === 0 && !isLoading && (
                        <Div styles={noResultsStyles}>
                            {localizationService.str(
                                'ui.runtimessr.collectionSearch.noResults'
                            )}
                        </Div>
                    )}
                </>
            )}

            <HiddenItemForEditorComputedStyles styles={styles} />
        </Div>
    );
};

const dropdownStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        textAlign: 'left',
        overflowY: 'auto',
        maxHeight: '1000px',
    },
};

const noResultsStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        color: '#a1a1a1',
        padding: '30px 40px',
        borderTop: '1px solid #E2E2E2',
    },
    [RuntimeMediaQuery.MOBILE]: {
        padding: 20,
    },
};
