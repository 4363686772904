import { FeatureFlags } from 'shared-services/types/feature-flags';
import { commonProps } from '@duda-co/host-services';

const FLAG_PREFIX = 'runtime.ssr.';

export const featureFlags: FeatureFlags = {
    getBoolean: (key, defaultValue = false) => {
        if (!key.startsWith(FLAG_PREFIX)) {
            throw new Error(`Flag must contains ${FLAG_PREFIX}`);
        }
        return commonProps.getFlag(key, defaultValue) as boolean;
    },
    getServiceFlags: () => {
        throw new Error('not implemented');
    },
    initServiceFlags: async () => {
        throw new Error('not implemented');
    },
};
