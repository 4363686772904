import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import { Div } from 'client/widget-components/basicComponents';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import React, { FC } from 'react';
import SlideContent, { SlideContentProps } from '../SlideContent';
import { SlideData } from '../sliderCommonTypes';
import { SlideShowProps } from './SlideShowCommon';

type FadeSlotProps = {
    onAnimationEnd: () => void;
    currentSlideData: SlideData;
    previousSlideData: SlideData;
    isTransitioning: boolean;
    shouldAnimateContent: boolean;
    slideProps: SlideShowProps['slideProps'];
    styles: ResponsiveStylesProp;
};

export const FadeSlot: FC<FadeSlotProps> = ({
    onAnimationEnd,
    isTransitioning,
    currentSlideData,
    previousSlideData,
    slideProps,
    shouldAnimateContent,
    styles,
}) => {
    const currentContentAnimMode: SlideContentProps['contentAnimationMode'] =
        !shouldAnimateContent
            ? 'off'
            : isTransitioning
            ? 'anim-idle'
            : 'anim-active';
    return (
        <Div
            styles={[
                {
                    [RuntimeMediaQuery.COMMON]: {
                        position: 'relative',
                    },
                },
                styles,
            ]}
        >
            {isTransitioning && (
                <Div
                    styles={[SlideContainerStyles, previousSlideStyles]}
                    key={previousSlideData.uuid}
                >
                    <SlideContent {...slideProps} {...previousSlideData} />
                </Div>
            )}
            <Div
                data-auto='slideSlot'
                styles={[SlideContainerStyles, currentSlideStyles]}
                onAnimationEnd={onAnimationEnd}
                key={currentSlideData.uuid}
                className='d-ext-mediaSlider-slidesContainer__slide'
            >
                <SlideContent
                    contentAnimationMode={currentContentAnimMode}
                    {...slideProps}
                    {...currentSlideData}
                />
            </Div>
        </Div>
    );
};
const SlideContainerStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
};

const currentSlideStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        animationName:
            'fadeIn' /* Why using global animation keyframe intentionally?
        - Ideal might be to use keyframes locally. But styled components version 5 doesn't work well for adding a keyframe within a nested object media query, as we work for styling
        - The alternative would be to use transition animation. But since this element represents the current slide, it should never fade out, just fade in, so we need to render it to the dom and immediately change opacity from 0 to 1. It's not so trivial and created challenges.*/,
        animationDuration: '1s',
        opacity: 1, // current slide becomes previous slide, and this property allows for the transition to take place
        animationTimingFunction: 'ease-in',
    },
};

const previousSlideStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        opacity: 0,
        transition: 'opacity 1s ease-in',
    },
};
