import { debounce } from 'client/utils';
import { useCallback, useEffect, useRef } from 'react';

const useDebouncedCallback = ({
    callback,
    msToWait = 300,
}: {
    callback: (args?: any) => unknown;
    msToWait?: number;
}) => {
    const latestCallback = useRef(callback);

    useEffect(() => {
        latestCallback.current = callback;
    }, [callback]);

    return useCallback(
        debounce((...args: any[]) => {
            latestCallback.current(...args);
        }, msToWait),
        []
    );
};

export default useDebouncedCallback;
