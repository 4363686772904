import React from 'react';
import { Pagination } from 'client/widget-components';
import { AnimationType, SliderLayoutProps } from '../sliderCommonTypes';
import SliderSlideShow from '../slideShow/SliderSlideShow';
import SliderFadeShow from '../slideShow/SliderFadeShow';
import { useSliderPagination } from '../useSliderPagination';
import { Div } from 'client/widget-components/basicComponents';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import { paginationStylesByShowType } from './sliderLayoutView.helpers';
import { SlideShowProps } from '../slideShow/SlideShowCommon';
import { DUDA_ASSET_CDN } from 'client/consts';
import { useSliderSwipe } from '../useSliderSwipe';
import SliderSlideShowCleanWrap from '../slideShow/SliderSlideShowCleanWrap';

const getAnimationByType = ({
    animationType,
    props,
}: {
    animationType: AnimationType;
    props: SlideShowProps;
}) => {
    switch (animationType) {
        case 'fade':
            return <SliderFadeShow {...props} />;
        case 'slide':
            return props.slideAnimationProps.slotsInFrame === 1 &&
                props.slideAnimationProps.layout === 'LAYOUT_3_B' ? (
                <SliderSlideShowCleanWrap {...props} />
            ) : (
                <SliderSlideShow {...props} />
            );
        default:
            return <SliderFadeShow {...props} />;
    }
};

const SliderLayoutView: React.FC<SliderLayoutProps> = ({
    slidesData,
    styles,
    paginationType,
    animationType = AnimationType.Slide,
    slotsInFrame,
    selectedIndex,
    autoPagination,
    dataAuto,
    paginationShow,
    arrowStyle,
    layout,
    ...otherProps
}) => {
    const {
        onmouseenter,
        onmouseleave,
        paginationAction,
        cursor,
        newEnters,
        onPageTransitionCompleted,
        restartAutoPagination,
    } = useSliderPagination({
        selectedIndex,
        totalPages: slidesData.length,
        autoPagination: autoPagination,
    });

    const { handleTouchStart, handleTouchEnd } = useSliderSwipe({
        onSwipeForward: () => {
            paginationAction.goPrevious();
            restartAutoPagination();
        },
        onSwipeBack: () => {
            paginationAction.goNext();
            restartAutoPagination();
        },
    });

    const slideShowProps: SlideShowProps = {
        slideAnimationProps: {
            cursor,
            slotsInFrame,
            newEnters,
            layout,
            paginationType,
            ...otherProps,
        },
        slideProps: {
            styles: styles.slide,
            onContentAnimationCompleted: onPageTransitionCompleted,
            ...otherProps,
        },
        slidesData,
    };

    return (
        <Div
            styles={[
                containerDefaultStyle,
                styles?.container,
                paginationStylesByShowType(paginationShow),
            ]}
            onMouseEnter={onmouseenter}
            onMouseLeave={onmouseleave}
            data-auto={dataAuto}
        >
            <Div
                data-auto='slider-slides-container'
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                styles={[slidesContainerDefaultStyles, styles.slidesContainer]}
            >
                {getAnimationByType({
                    animationType,
                    props: slideShowProps,
                })}
            </Div>
            <Pagination
                arrowStyle={arrowStyle}
                type={paginationType}
                styles={styles?.pagination}
                cursor={cursor}
                totalPages={slidesData?.length}
                {...paginationAction}
                thumbList={slidesData.map(
                    (slideData) =>
                        slideData.media ?? {
                            imgSrc: `${DUDA_ASSET_CDN}/runtime/ssr-slider/no-image-pagination-thumb.png`,
                        }
                )}
            />
        </Div>
    );
};

const containerDefaultStyle: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        height: '100%',
        alignItems: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        position: 'relative',
    },
};

const slidesContainerDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
    },
};

export default SliderLayoutView;
