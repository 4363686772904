function isObject(item: any): item is Record<string, any> {
    return item && typeof item === 'object' && !Array.isArray(item);
}

export function mergeDeepPure<T extends Record<string, any>>(
    object1: T,
    object2: T
): T {
    const newObject = { ...object1 };
    (Object.keys(object2) as Array<keyof T>).forEach((key) => {
        if (isObject(object1[key]) && isObject(object2[key])) {
            newObject[key] = mergeDeepPure(object1[key], object2[key]);
        } else {
            newObject[key] = object2[key];
        }
    });

    return newObject;
}

type DebounceFunction = <T extends (...args: any[]) => any>(
    func: T,
    delay: number
) => (...args: Parameters<T>) => void;

export const debounce: DebounceFunction = (func, delay) => {
    let timeoutId: ReturnType<typeof setTimeout>;

    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
};

export function isObjectEmpty(obj: object): boolean {
    if (obj == null) {
        return true;
    }
    return Object.keys(obj).length === 0;
}

export function pick<T extends Record<string, unknown>, K extends string>(
    obj: T,
    keys: ReadonlyArray<K>
): Pick<T, K> {
    return keys.reduce((result, key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
            result[key] = obj[key];
        }
        return result;
    }, {} as Pick<T, K>);
}
