import React from 'react';
import { Div } from 'client/widget-components/basicComponents';
import RangeSliderTrack from './RangeSliderTrack';
import RangeInput from '../RangeInput';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import { InputsProps, POINTS, RangeValues } from '../types';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import useRangeSliderLogic from './useRangeSliderLogic';

export interface RangeInputsProps extends InputsProps {
    rangeValues: RangeValues;
    onChange: (rangeValue: RangeValues) => void;
}

function RangeSliderInputs(props: RangeInputsProps) {
    const { rangeValues } = props;
    const {
        wrapperEvents,
        trackRefCB,
        onStartInputChange,
        onEndInputChange,
        activeSlider,
    } = useRangeSliderLogic(props);

    return (
        <Div styles={inputWrapperDefaultStyles} {...wrapperEvents}>
            <RangeSliderTrack
                trackStyles={props.trackStyles}
                min={props.min}
                max={props.max}
                rangeValues={rangeValues}
                hideRangeTrack={props.hideRangeTrack}
                trackDataGrab={props.trackDataGrab}
                ref={trackRefCB}
            />
            <RangeInput
                min={props.min}
                max={props.max}
                value={rangeValues.start}
                data-auto='range-slider-start-input'
                thumbStyles={props.thumbStyles}
                trackStyles={props.trackStyles}
                onChange={onStartInputChange}
                isActive={activeSlider === POINTS.START}
            />
            <RangeInput
                min={props.min}
                max={props.max}
                value={rangeValues.end}
                data-auto='range-slider-end-input'
                thumbStyles={props.thumbStyles}
                trackStyles={props.trackStyles}
                onChange={onEndInputChange}
                isActive={activeSlider === POINTS.END}
            />
        </Div>
    );
}

const inputWrapperDefaultStyles: ResponsiveStylesProp = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        marginBlock: '12px',
        cursor: 'pointer',
        '::before': {
            content: '""',
            position: 'absolute',
            top: '-8px',
            right: '0',
            bottom: '-8px',
            left: '0',
        },
    },
};
export default RangeSliderInputs;
