import { PaginationProps, PaginationType } from 'client/widget-components';
import {
    ArrowStyle,
    ButtonContent,
    MediaBaseData,
} from 'client/widget-components/CommonModelTypes';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import React from 'react';
import { ImageLayout, SlideContentPropsBase } from './SlideContent';

export type Prefix<K extends string, T extends string> = `${K}_${T}`;

export type PrefixShapeKeys<K, T extends string> = {
    [P in keyof K as Prefix<T, string & P>]: K[P];
};

type LayoutSpecificStyles = {
    // keep partial to make sure that SliderLayout is optional
    layoutSpecificStyles?: Partial<Record<SliderLayout, SliderStyles>>;
};

export type Styles = LayoutSpecificStyles & SliderStyles;

export type SliderStyles = {
    container?: ResponsiveStylesProp;
    slidesContainer?: ResponsiveStylesProp;
} & PrefixShapeKeys<SlideContentPropsBase['styles'], 'slide'> &
    PrefixShapeKeys<PaginationProps['styles'], 'pagination'>;

export interface SlideData {
    uuid: string;
    title?: string;
    desc?: string;
    media?: MediaBaseData;
    button?: ButtonContent;
    linkDomAttributes?: Record<string, string>;
    showButton: boolean;
    imgCssObjectPositionValue?: string;
}

export interface AutoPaginationProps {
    on: boolean;
    intervalInSeconds: number;
    pauseOnHover?: boolean;
}
export enum AnimationType {
    Slide = 'slide',
    Fade = 'fade',
}
export enum PaginationShow {
    OnHover = 'onHover',
    Always = 'always',
    Never = 'never',
}
export interface RuntimeSliderBaseProps {
    slidesData: Array<SlideData>;
    selectedIndex?: number;
    shouldShowPaginationOnHover?: boolean;
    animationType?: AnimationType;
    autoPagination?: AutoPaginationProps;
    dataAuto?: string;
    showButtonHoverEffect?: boolean;
    paginationShow?: PaginationShow;
    arrowStyle?: ArrowStyle;
    contentAnimationTypeCssClass?: string;
    paginationType?: PaginationType;
}

export interface RuntimeSliderProps extends RuntimeSliderBaseProps {
    layout?: SliderLayout;
    _styles?: Styles;
    overrideAutoPaginationOn?: boolean;
    bindingSource?: string;
    slotsInFrame?: number;
}

export interface SliderLayoutProps extends RuntimeSliderBaseProps {
    imageLayout?: ImageLayout;
    styles: {
        container?: ResponsiveStylesProp;
        slidesContainer?: ResponsiveStylesProp;
        slide?: SlideContentPropsBase['styles'];
        pagination: PaginationProps['styles'];
    };
    slideAnimationDirection?: 'vertical' | 'horizontal';
    slotsInFrame: number;
    layout: SliderLayout;
}

export const PaginationPlacement = {
    middle: 'middle',
    bottom: 'bottom',
    side: 'side',
};

export interface StyledProps {
    styles?: React.CSSProperties;
}

export type WrapDirection = 'toLast' | 'toFirst' | undefined;
export const sliderLayouts = {
    LAYOUT_1: 'LAYOUT_1',
    LAYOUT_2: 'LAYOUT_2',
    LAYOUT_3: 'LAYOUT_3',
    LAYOUT_4: 'LAYOUT_4',
    LAYOUT_5: 'LAYOUT_5',
    LAYOUT_6: 'LAYOUT_6',
    LAYOUT_3_B: 'LAYOUT_3_B',
} as const;
export type SliderLayout = keyof typeof sliderLayouts;

export type LayoutDesignData = Pick<
    SliderLayoutProps,
    'paginationType' | 'imageLayout' | 'slideAnimationDirection'
> & {
    styles: Styles;
    defaultSlotsInFrame?: number;
    strictSlotsInFrame?: number;
};
export type SliderLayoutTypeToDesignData = Record<
    SliderLayout,
    LayoutDesignData
>;
export type SliderLayoutsPaginationOverrides = Partial<
    Record<PaginationType, Partial<SliderLayoutTypeToDesignData>>
>;
