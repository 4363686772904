import React from 'react';
import styled from 'styled-components';
import {
    DeprecatedResponsiveStyles,
    getResponsiveCssObject,
} from 'client/widget-components/responsive-styles/deprecated/styled-components-utils';

export enum TextDomTag {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
    paragraph = 'p',
}

export interface TextProps {
    tag?: TextDomTag;
    styles?: DeprecatedResponsiveStyles;
    domAttrs?: React.HTMLAttributes<HTMLElement>;
    'data-auto'?: string;
    children?: React.ReactNode;
}

const Tag = styled.div<{
    styles?: DeprecatedResponsiveStyles;
}>((props) => getResponsiveCssObject(props.styles));

/**
 * @todo TODO: Remove after moving to new responsive-styles entities
 */
const DeprecatedText: React.FC<TextProps> = ({
    tag,
    domAttrs,
    ...otherProps
}) => {
    return <Tag as={tag} {...domAttrs} {...otherProps} />;
};

export default DeprecatedText;
