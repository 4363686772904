import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';

export enum POINTS {
    START = 'start',
    END = 'end',
}
export type RangeValues = { start: number; end: number };

export interface RangeSliderProps extends LabelsProps, InputsProps {
    onChange: ({ start, end }: RangeValues) => void;
    currentValues?: RangeValues;
}

export interface InputsProps extends TrackProps {
    minRange?: number;
    thumbStyles?: ResponsiveStylesProp;
}

export interface TrackProps {
    trackDataGrab?: string;
    trackStyles?: ResponsiveStylesProp;
    min: number;
    max: number;
    hideRangeTrack?: boolean;
}

export interface LabelsProps {
    labelsWrapperStyles?: ResponsiveStylesProp;
    labelStyles?: ResponsiveStylesProp;
    sign?: string;
}
