import React from 'react';
import { Div } from 'client/widget-components/basicComponents';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';

export default function ({ size }: { size: number }) {
    return (
        <Div styles={warningBtnStyles} data-auto='warning-icon'>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width={size}
                height={size}
                viewBox={`0 0 ${size} ${size}`}
                fill='none'
            >
                <g clipPath='url(#clip0_2202_45766)'>
                    <path
                        d='M5.00019 20.0005H19.0002C19.3265 19.9982 19.6473 19.9161 19.9346 19.7614C20.2219 19.6066 20.4669 19.3839 20.6484 19.1127C20.8299 18.8415 20.9422 18.53 20.9756 18.2054C21.0091 17.8809 20.9626 17.553 20.8402 17.2505L13.7402 5.0005C13.5672 4.6879 13.3137 4.42733 13.0059 4.24589C12.6982 4.06445 12.3474 3.96875 11.9902 3.96875C11.6329 3.96875 11.2822 4.06445 10.9744 4.24589C10.6667 4.42733 10.4131 4.6879 10.2402 5.0005L3.14019 17.2505C3.02013 17.5461 2.97252 17.8661 3.00134 18.1838C3.03016 18.5015 3.13456 18.8078 3.30584 19.0769C3.47712 19.3461 3.7103 19.5703 3.98592 19.731C4.26153 19.8917 4.57158 19.9841 4.89019 20.0005'
                        fill='currentColor'
                    />
                    <path
                        d='M11.9902 9L11.9902 13'
                        stroke='white'
                        strokeWidth='1.8'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        d='M11.9902 17L12.0002 17'
                        stroke='white'
                        strokeWidth='1.8'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                </g>
                <defs>
                    <clipPath id='clip0_2202_45766'>
                        <rect width={size} height={size} fill='white' />
                    </clipPath>
                </defs>
            </svg>
        </Div>
    );
}

const warningBtnStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        position: 'absolute',
        left: '-12px',
        top: '-12px',
        color: '#f4a622',
    },
};
