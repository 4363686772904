import React, { CSSProperties, useMemo } from 'react';
import { useSnipcartApi } from 'client/widget-components';
import styled from 'styled-components';
import SvgIcon from './SvgIcon';
import {
    DeprecatedResponsiveStyles,
    getBreakpointsStyle,
    upgradeStyles,
    DeprecatedWidgetStyles,
} from 'client/widget-components/responsive-styles/deprecated/styled-components-utils';
import { RuntimeWidget } from 'client/types';
import { useEditorContext } from 'client/infra/renderers/editorState';
import withErrorBoundary from "../../widget-components/components/ErrorBoundary/withErrorBoundary";

export interface CartProps {
    svgMarkup?: string;
    _styles?: {
        itemsCountLabel?: CSSProperties;
        cartIcon?: CSSProperties;
    };
}

const RuntimeCart: RuntimeWidget<CartProps> = ({ svgMarkup, _styles }) => {
    const { isInEditor } = useEditorContext();
    const { itemsCount, openSnipcart } = useSnipcartApi();

    const onCartClick = () => {
        !isInEditor && openSnipcart();
    };

    const upgradedStyles = useMemo(() => {
        return upgradeStyles(_styles as DeprecatedWidgetStyles);
    }, [_styles]);

    return (
        <CartWrapper data-auto='ssr-cart-widget'>
            <SvgIcon
                className='cart-icon'
                dataAuto='cart-icon'
                svgMarkup={svgMarkup}
                styles={upgradedStyles.rules?.cartIcon || {}}
                onClick={onCartClick}
            />
            <StyledLabel
                styles={upgradedStyles.rules?.itemsCountLabel}
                className='cart-count-label'
                data-auto='cart-count-label'
                onClick={onCartClick}
            >
                {isInEditor ? itemsCount : itemsCount > 0 && itemsCount}
            </StyledLabel>
        </CartWrapper>
    );
};

const CartWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    .cart-icon,
    .cart-count-label {
        cursor: pointer;
        user-select: none;
    }

    label.cart-count-label {
        margin-inline: 5px;
    }
`;

interface StyledLabelProps {
    styles: CSSProperties | DeprecatedResponsiveStyles | undefined;
}

const StyledLabel = styled.label<StyledLabelProps>`
    ${(props: StyledLabelProps) => getBreakpointsStyle(props.styles)}
`;

export default withErrorBoundary({
    Comp: RuntimeCart,
    logProps: true,
    componentName: 'Cart',
    additionalInfoToLog: {
        tag: 'native-ecom'
    },
});

