import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import { SlideContentProps } from '../SlideContent';
import { SlideData, SliderLayoutProps } from '../sliderCommonTypes';

export type SlideShowProps = {
    slideAnimationProps: {
        slotsInFrame: number;
        cursor: number;
        newEnters: number;
    } & Pick<
        SliderLayoutProps,
        'slideAnimationDirection' | 'layout' | 'paginationType'
    >;
    slideProps: Pick<
        SlideContentProps,
        | 'showButtonHoverEffect'
        | 'onContentAnimationCompleted'
        | 'imageLayout'
        | 'styles'
    > &
        Pick<SliderLayoutProps, 'contentAnimationTypeCssClass'>;
    slidesData: Array<SlideData>;
};

interface SlideShowCommonDefaultStyles {
    getContainer: (numOfSlideSlots: number) => ResponsiveStylesStructured;
    filmRole: ResponsiveStylesStructured;
    getSlideSlot: (numberOfUnits: number) => ResponsiveStylesStructured;
    slotParent: ResponsiveStylesStructured;
}

export const slideShowCommonDefaultStyles: SlideShowCommonDefaultStyles = {
    getContainer: (numOfSlideSlots) => ({
        [RuntimeMediaQuery.COMMON]: {
            width: '100%',
            height: '100%',
        },
        [RuntimeMediaQuery.MOBILE]:
            numOfSlideSlots < 3
                ? {}
                : {
                      position: 'absolute',
                      left: `-75%`,
                      width: `250%`,
                  },
    }),
    filmRole: {
        [RuntimeMediaQuery.COMMON]: {
            display: 'flex',
        },
    },

    slotParent: {
        [RuntimeMediaQuery.COMMON]: {
            flex: '1',
            position: 'relative',
        },
    },

    getSlideSlot: (numberOfUnits: number) => {
        const showGap = numberOfUnits > 1;
        const inline = showGap ? '3%' : 0;
        const inlineMobile = showGap ? '2%' : 0;

        return {
            [RuntimeMediaQuery.COMMON]: {
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: inline,
                right: inline,
            },
            [RuntimeMediaQuery.MOBILE]: {
                left: inlineMobile,
                right: inlineMobile,
            },
        };
    },
};
