import React from 'react';
import { FilterableField, FilterIDS, FilterTypes } from '../utils/types';
import FilterItem from './FilterItem';
import { useFilterSortContext } from '../utils/filterSortContext';

interface FilterListProps {
    values: Array<FilterableField>;
    currentSelection: {
        [filterName: string]: FilterableField;
    };
    onChange: (filterValue: FilterableField) => void;
}
function FiltersList({ values, currentSelection, onChange }: FilterListProps) {
    const { inDesignMode, hasProducts, currentPageType } =
        useFilterSortContext();
    return (
        <>
            {values.map((filterField) => {
                if (filterField.disabled && !inDesignMode) {
                    return null;
                }
                if (
                    shouldHideFilterItem({
                        filterField,
                        hasProducts,
                        currentPageType,
                    })
                ) {
                    return null;
                }
                const filterId = filterField.fieldId;
                const filterType = filterField.filterType || FilterTypes.ONE_OF;
                const filterSelectedValues =
                    currentSelection[filterId]?.selectedValues || undefined;
                return (
                    <FilterItem
                        key={filterId}
                        item={filterField}
                        type={filterType}
                        currentValues={filterSelectedValues}
                        onChange={(selectedValues) => {
                            onChange({
                                ...filterField,
                                filterType,
                                selectedValues,
                            });
                        }}
                    />
                );
            })}
        </>
    );
}

function shouldHideFilterItem({
    filterField,
    hasProducts,
    currentPageType,
}: {
    filterField: FilterableField;
    hasProducts?: boolean;
    currentPageType?: string;
}) {
    const filterId = filterField.fieldId;
    if (filterId === FilterIDS.CATEGORY_ID) {
        const isCategoryPage = currentPageType === 'STORE_CATEGORY_PAGE';
        return (
            isCategoryPage || Object.keys(filterField.filtersData).length === 0
        );
    }
    if (filterId === FilterIDS.PRICE) {
        return !hasProducts;
    }
}
export default FiltersList;
