import Text, {
    TextDomTag,
} from 'client/widget-components/basicComponents/Text';
import React, { ComponentProps } from 'react';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import { ErrorIcon } from '../icons';

export interface FieldErrorProps
    extends Omit<ComponentProps<typeof Text>, 'children'> {
    error: React.ReactNode | null;
    dataGrab?: string;
    dataAuto?: string;
}

const FieldError = ({
    error,
    dataAuto = 'field-error',
    tag = TextDomTag.paragraph,
    styles,
    ...restProps
}: FieldErrorProps) => {
    return (
        <Text
            data-auto={dataAuto}
            tag={tag}
            {...restProps}
            styles={[fieldErrorDefaultStyles, styles]}
        >
            <ErrorIcon size={16} />
            {error}
        </Text>
    );
};

const fieldErrorDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        padding: '2px',
        color: '#DC1C1C',
        fontSize: '12px',
        margin: 0,
    },
};

export default FieldError;
