type StringFunc = (x: string) => string;

function simpleStringMemoise(passedFunc: StringFunc): StringFunc {
    var cache: Record<string, string> = {};
    return (x: string) => {
        if (x in cache) return cache[x];
        return (cache[x] = passedFunc(x));
    };
}

function kebabToCamelCase(str: string) {
    return str.replace(/-./g, (x) => x[1].toUpperCase());
}

const memoisedKebabToCamel = simpleStringMemoise(kebabToCamelCase);

function convertLeafKeysToCamelCase<T extends any>(obj: T): T {
    const newObj: any = {};
    Object.entries(obj as any).forEach(([key, val]) => {
        if (typeof val === 'object' && val != null) {
            newObj[key] = convertLeafKeysToCamelCase(val);
        } else {
            newObj[memoisedKebabToCamel(key)] = val;
        }
    });

    return newObj as T;
}

const normalizeStylesToCamelCase = convertLeafKeysToCamelCase;

export default normalizeStylesToCamelCase;
