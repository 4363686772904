import React, { ReactNode } from 'react';
import styled from 'styled-components';

export enum TransitionType {
    slideFromRight = 'slideFromRight',
    slideFromLeft = 'slideFromLeft',
    slideFromTop = 'slideFromTop',
    slideFromBottom = 'slideFromBottom',
    fade = 'fade',
}

interface SlideProps {
    distanceFromIndex: number;
    transitionType?: TransitionType;
    transitionDuration?: number;
}
export interface CardsDeckProps extends Omit<SlideProps, 'distanceFromIndex'> {
    cards: Card[];
    children: (data: any) => ReactNode;
    cursor?: number;
    onMouseEnter?: (event: React.MouseEvent<HTMLDivElement>) => void;
    onMouseLeave?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export interface Card {
    uuid: string;
}

const defaultProps = {
    cards: [],
    cursor: 0,
    transitionDuration: 1,
    transitionType: TransitionType.slideFromRight,
};

const StyledDeck = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
`;

const transitionPropByType: Record<TransitionType, string> = {
    [TransitionType.slideFromBottom]: 'top',
    [TransitionType.slideFromTop]: 'bottom',
    [TransitionType.slideFromRight]: 'left',
    [TransitionType.slideFromLeft]: 'right',
    [TransitionType.fade]: 'opacity',
};

const getCardStyle = ({
    distanceFromIndex,
    transitionDuration,
    transitionType,
}: SlideProps) => {
    const transitionProp =
        transitionPropByType[transitionType || TransitionType.slideFromRight];
    switch (transitionType) {
        case TransitionType.fade:
            return {
                left: 0,
                opacity: `${distanceFromIndex === 0 ? 1 : 0}`,
                transition: `${transitionProp} ${transitionDuration}s`,
            };
        default:
            return {
                [transitionProp]: `${
                    distanceFromIndex > 0
                        ? '100%'
                        : distanceFromIndex === 0
                        ? '0'
                        : '-100%'
                }`,
                transition: `${transitionProp} ${transitionDuration}s`,
            };
    }
};

const StyledCard = styled.div<SlideProps>`
    width: 100%;
    height: 100%;
    bottom: 0;
    position: absolute;
    ${(props) => ({ ...getCardStyle(props) })}
`;

const CardsDeck: React.FC<CardsDeckProps> = ({
    children,
    cards,
    cursor,
    transitionDuration,
    transitionType,
    ...props
}) => {
    return (
        <StyledDeck {...props}>
            {cards.map((card, index) => {
                return (
                    <StyledCard
                        key={card?.uuid}
                        distanceFromIndex={index - (cursor || 0)}
                        data-auto={`card-${index}`}
                        transitionDuration={transitionDuration}
                        transitionType={transitionType}
                    >
                        {children(card)}
                    </StyledCard>
                );
            })}
        </StyledDeck>
    );
};

CardsDeck.defaultProps = defaultProps;

export default CardsDeck;
