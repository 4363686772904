import { useEffect, useState } from 'react';
import { CollectionFilter, FilterableField, SortDirection } from './types';

export function useFilterSortService(collectionId: string) {
    const [filterSortService, setFilterSortService] =
        useState<CollectionFilter | null>(null);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }
        const { collectionsFilterService } = window.runtime || {};
        if (collectionsFilterService) {
            setFilterSortService({
                setCollectionSort: (
                    sortBy: string,
                    sortDirection: SortDirection | null
                ) =>
                    collectionsFilterService.setCollectionSort(
                        collectionId,
                        sortBy,
                        sortDirection
                    ),
                setCollectionFilter: (
                    filterName: string,
                    filterValue: FilterableField
                ) =>
                    collectionsFilterService.setCollectionFilter(
                        collectionId,
                        filterName,
                        filterValue
                    ),
                onCollectionValueChange:
                    collectionsFilterService.onCollectionValueChange,
                clearCollectionValues: () =>
                    collectionsFilterService.clearCollectionValues(
                        collectionId
                    ),
            });
        }
    }, []);

    return filterSortService;
}
