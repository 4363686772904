import { featureFlags } from '@shared-services/feature-flags';
import { logger } from '@shared-services/log-service';
import { getWindowInBrowser } from '../../RuntimeWindow';
import { getWrapperId } from './utils';

const loggedIds = new Set<string>();

const getMarkupForLogs = (preHydration: string, id: string) => {
    const shouldLogHtml = featureFlags.getBoolean(
        'runtime.ssr.log.showHydrationDiff',
        false
    );
    if (!shouldLogHtml) {
        return {};
    }
    try {
        const withoutHydrationScript = preHydration.replace(
            /<script\s+data-role="hydration"[^>]*>[\s\S]*?<\/script>/gi,
            ''
        );
        const postHydration =
            document.getElementById(getWrapperId(id))?.outerHTML || 'null';
        return {
            preHydration: withoutHydrationScript,
            postHydration,
        };
    } catch (e) {
        return {
            preHydration,
            postHydration: `Error while getting post-hydration markup: ${e}`,
        };
    }
};
export const logDiff = (
    preHydration: string,
    error: unknown,
    errorInfo: unknown,
    type: string,
    id: string
) => {
    const shouldLogErrors = featureFlags.getBoolean(
        'runtime.ssr.log.showHydrationErrors',
        true // use flag to turn off logging, enabled by default
    );
    if (!shouldLogErrors || loggedIds.has(id)) {
        return;
    }
    loggedIds.add(id);
    const { Parameters } = getWindowInBrowser();

    const isEditor = Parameters?.isInEditor;
    const logData = {
        message: 'SSR hydration mismatch between server and client render',
        error,
        errorInfo,
        widget: type,
        siteAlias: Parameters?.SiteAlias,
        isEditor,
        ...getMarkupForLogs(preHydration, id),
    };
    if (isEditor) {
        logger.debug(logData);
    } else {
        logger.warn(logData);
    }
};
