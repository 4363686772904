import React, { useEffect, useState } from 'react';
import { ArrowIcon } from '../icons';
import { Div } from 'client/widget-components/basicComponents';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import Text, {
    TextDomTag,
} from 'client/widget-components/basicComponents/Text';

export interface AccordionProps {
    title: string;
    children: React.ReactNode;
    isAccordionOpen?: boolean;
    styles?: {
        container?: ResponsiveStylesProp;
        headerWrapper?: ResponsiveStylesProp;
        title?: ResponsiveStylesProp;
        contentWrapper?: ResponsiveStylesProp;
    };
    dataGrabs?: {
        titleDataGrab?: string;
        textWrapperDataGrab?: string;
    };
}
function Accordion({
    title,
    children,
    styles,
    isAccordionOpen = false,
    dataGrabs,
    ...rest
}: AccordionProps) {
    const [isOpen, setIsOpen] = useState(isAccordionOpen);

    useEffect(() => {
        setIsOpen(isAccordionOpen);
    }, [isAccordionOpen]);
    const toggleAccordion = () => {
        setIsOpen((open) => !open);
    };
    return (
        <Div styles={[wrapperDefaultStyles, styles?.container]} {...rest}>
            <Div
                styles={[headerWrapperStyles, styles?.headerWrapper]}
                onClick={toggleAccordion}
                data-auto='accordion-trigger'
                data-grab={dataGrabs?.textWrapperDataGrab}
            >
                <Text
                    styles={[labelDefaultStyles, styles?.title]}
                    data-grab={dataGrabs?.titleDataGrab}
                    tag={TextDomTag.h4}
                >
                    {title}
                </Text>
                <Text
                    styles={[getArrowStyles(isOpen), styles?.title]}
                    tag={TextDomTag.h4}
                >
                    <ArrowIcon />
                </Text>
            </Div>
            <Div
                styles={listDefaultStyles}
                style={{ display: isOpen ? 'flex' : 'none' }}
                data-auto='accordion-list'
            >
                {children}
            </Div>
        </Div>
    );
}

const headerWrapperStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        textTransform: 'uppercase',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19px',
        gap: '8px',
        lineBreak: 'anywhere',
    },
};

const wrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: '100%',
    },
};
const labelDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        maxWidth: '90%',
        cursor: 'pointer',
        flex: 1,
        textDecoration: 'inherit',
        margin: 0,
    },
};
const listDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        marginBlockStart: '16px',
        flexDirection: 'column',
        gap: '8px',
        display: 'flex',
    },
};
function getArrowStyles(isOpen: boolean): ResponsiveStylesStructured {
    return {
        [RuntimeMediaQuery.COMMON]: {
            display: 'grid',
            alignItems: 'center',
            transform: `rotate(${isOpen ? '0' : '180deg'})`,
            aspectRatio: '1/1',
            margin: 0,
        },
    };
}

export default Accordion;
