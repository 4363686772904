import styled from 'styled-components';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { toCSSObject } from 'client/widget-components/responsive-styles/toCSSObject';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';

interface Props {
    styles?: ResponsiveStylesProp;
}

const Video = styled.video<Props>(({ styles }) =>
    toCSSObject([videoNormalizeStyles, styles], true)
);

const videoNormalizeStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        margin: 'revert',
    },
};

export default Video;
