import { useEffect, useState, useRef } from 'react';
import { getWindowInBrowser } from 'client/RuntimeWindow';
import { AutoPaginationProps } from 'client/widgets/runtime-slider/sliderCommonTypes';
import { useEditorContext } from 'client/infra/renderers/editorState';

export const NO_TOTAL_PAGES_ERROR =
    'The UsePagination hook must receive a totalPages argument for it to work';

export const useSliderPagination = ({
    totalPages,
    selectedIndex = 0,
    autoPagination,
}: {
    totalPages: number;
    selectedIndex?: number | undefined;
    autoPagination?: AutoPaginationProps;
}) => {
    const [cursor, setCursor] = useState(selectedIndex);
    const [newEnters, setNewEnters] = useState(0);
    const [hovered, setHovered] = useState(false);
    const timerInterval = useRef<number>();
    const { isInEditor } = useEditorContext();
    useEffect(() => {
        setCursor(selectedIndex);
    }, [selectedIndex]);

    const goNext = () => {
        setCursor((currentCursor) => {
            const newCursorCandidate = currentCursor + 1;
            return newCursorCandidate === totalPages ? 0 : newCursorCandidate;
        });
        setNewEnters((n) => n - 1);
    };

    const goPrevious = () => {
        setCursor((currentCursor) => {
            const newCursorCandidate = currentCursor - 1;
            return newCursorCandidate === -1
                ? totalPages - 1
                : newCursorCandidate;
        });
        setNewEnters((n) => n + 1);
    };

    const goToCursor = (newCursor: number) => {
        setCursor(newCursor);
        setNewEnters(cursor - newCursor);
    };

    const restartAutoPagination = () => {
        if (autoPagination?.on) {
            clearInterval(timerInterval.current);
            timerInterval.current = getWindowInBrowser().setInterval(() => {
                goNext();
            }, autoPagination.intervalInSeconds * 1000);
        }
    };

    useEffect(() => {
        // I could not find a way to optimize nested conditions. I will leave it as is.
        if (hovered) {
            if (!isInEditor) {
                if (!autoPagination?.pauseOnHover) {
                    restartAutoPagination();
                }
            }
        } else {
            restartAutoPagination();
        }

        return () => {
            clearInterval(timerInterval.current);
        };
    }, [autoPagination, hovered]);

    return {
        cursor,
        restartAutoPagination,
        newEnters,
        paginationAction: {
            goToCursor,
            goNext,
            goPrevious,
        },
        onmouseenter: () => {
            setHovered(true);
        },
        onmouseleave: () => {
            setHovered(false);
        },
        onPageTransitionCompleted: () => {
            setNewEnters(0);
        },
    };
};
