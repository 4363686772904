export function hasProductGalleryOnPage() {
    return !!globalThis?.document?.querySelector(
        '[data-element-type="dSnipcartProductGalleryId"]'
    );
}

const widgetSelectors = {
    menuSelectors: {
        wrapper: null,
        list: 'filter-sort-menu-list',
        divider: 'filter-sort-menu-divider',
        closeIcon: 'filter-sort-menu-closeIcon',
    },
    inputsSelectors: {
        slider: 'range-slider-',
        input: 'filterItem-input-icon-wrapper',
        inputIcon: 'input-icon',
        inputLabel: 'filterItem-input-label',
    },
    textSelectors: {
        displayName: 'filter-sort-menu-displayName',
        title: 'filter-sort-menu-item-title',
    },
};
export const menuSelectors = widgetSelectors.menuSelectors;
export const inputsSelectors = widgetSelectors.inputsSelectors;
export const textSelectors = widgetSelectors.textSelectors;
