import { ContextService } from 'shared-services/types/context-service';

export const context: ContextService = {
    get isEditor() {
        try {
            return window.$?.DM?.insideEditor?.();
        } catch (e) {
            return false;
        }
    },
    get isServer() {
        return false;
    },
    get isBrowser() {
        return true;
    },
};
