import React, { FC, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { getWindowInBrowser } from 'client/RuntimeWindow';

interface PortalProps {
    portalRootSelector?: string;
    children?: React.ReactNode;
}

export const DeprecatedPortal: FC<PortalProps> = ({
    portalRootSelector = '#site_content',
    children,
}) => {
    const [portalElement, setPortalElement] = useState<HTMLDivElement | null>(
        null
    );

    useEffect(() => {
        const element = getWindowInBrowser()?.document?.createElement('div');
        const portalRootElement =
            getWindowInBrowser()?.document?.querySelector(portalRootSelector);
        portalRootElement?.appendChild(element);
        setPortalElement(element);

        return () => {
            if (element) {
                portalRootElement?.removeChild(element);
            }
        };
    }, []);

    if (portalElement) {
        return createPortal(children, portalElement);
    }

    return null;
};

function Portal({ portalRootSelector = '.dmContent', children }: PortalProps) {
    const root = useMemo(() => {
        return getWindowInBrowser()?.document?.querySelector(
            portalRootSelector
        );
    }, []);

    if (!root) {
        return null;
    }
    return createPortal(children, root);
}

export default Portal;
