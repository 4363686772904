import { MediaBaseData } from 'client/widget-components/CommonModelTypes';
import { Media } from 'client/widget-components/components/Media';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import React from 'react';
import { FC } from 'react';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';

interface AccordionMediaDefaultProps {
    styles?: ResponsiveStylesProp;
}

export const AccordionMediaDefault: FC<
    AccordionMediaDefaultProps & MediaBaseData
> = ({ styles, ...rest }) => {
    return (
        <Media
            styles={[mediaDefaultStyles, styles]}
            dataGrab='accordion-item-media'
            {...rest}
        />
    );
};

const mediaDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: 123,
        borderRadius: 0,
        borderStyle: 'solid',
        maxWidth: 'unset',
    },
    [RuntimeMediaQuery.MOBILE]: {
        maxWidth: '100%',
    },
};
