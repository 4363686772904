import React, { FC } from 'react';
import styled from 'styled-components';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { toCSSObject } from 'client/widget-components/responsive-styles/toCSSObject';

export interface LinkProps {
    styles?: ResponsiveStylesProp;
    grabId?: string;
    domAttrs?: React.AnchorHTMLAttributes<HTMLAnchorElement>;
    children?: React.ReactNode;
    increaseSpecificity?: boolean;
}

const _StyledLink = styled.a<{
    styles?: ResponsiveStylesProp;
}>((props) => toCSSObject(props.styles, true));

const StyledLink: FC<LinkProps> = ({ grabId, domAttrs, ...rest }) => {
    return (
        <_StyledLink
            data-auto={grabId}
            data-grab={grabId}
            {...domAttrs}
            {...rest}
        />
    );
};

export default StyledLink;
