import usePrevious from 'client/widget-components/customHooks/usePrevious';
import { useEffect, useState } from 'react';
import { SlideData, WrapDirection } from '../sliderCommonTypes';

const useWrapDirection = (cursor: number, slidesData: Array<SlideData>) => {
    const previousCursor = usePrevious(cursor);
    const [wrapDirection, setWrapDirection] = useState<WrapDirection>();
    useEffect(() => {
        if (previousCursor === 0 && cursor === slidesData.length - 1) {
            setWrapDirection('toLast');
        }
        if (previousCursor === slidesData.length - 1 && cursor === 0) {
            setWrapDirection('toFirst');
        }
    }, [previousCursor, cursor, slidesData.length]);
    const resetWrapDirection = () => {
        setWrapDirection(undefined);
    };
    return { wrapDirection, resetWrapDirection };
};
export default useWrapDirection;
