import React from 'react';

export default function () {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='10'
            height='5'
            viewBox='0 0 10 5'
            fill='none'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.005 8.66907e-07L9.94934 5L0.0468741 5L5.005 8.66907e-07Z'
                fill='#313131'
            />
        </svg>
    );
}
