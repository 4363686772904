import { MediaBaseData } from 'client/widget-components/CommonModelTypes';
import { Div, Img, Video } from 'client/widget-components/basicComponents';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import React, { FC } from 'react';
import { useIsHydrated } from '../../customHooks/useIsHydrated';

interface Props extends MediaBaseData {
    styles?: ResponsiveStylesProp;
    isHidden?: boolean;
    dataGrab?: string;
}

export const Media: FC<Props> = ({
    vidSrc,
    imgSrc,
    alt,
    type,
    styles,
    lazy,
    dataGrab,
    isHidden = false,
}) => {
    // If the image is hidden, we want to render a placeholder div to keep the layout consistent without loading the media.
    // We want to render the media in the client rendering, but also keep the server & client rendering consistent.
    // Therefore, if the image isn't visible at first, we render the div (both in server and in client) and the useEffect inside the custom hook indicates that the component is hydrated.
    const hydrated = useIsHydrated();
    const shouldRenderPlaceholder = isHidden && !hydrated;
    if (shouldRenderPlaceholder) {
        return <Div styles={styles} data-grab={dataGrab} />;
    }

    if (type === 'VIDEO') {
        return (
            <Video
                data-grab={dataGrab}
                src={vidSrc}
                poster={imgSrc}
                controls={false}
                autoPlay
                loop
                muted
                playsInline
                styles={styles}
            />
        );
    }
    return (
        <Img
            data-grab={dataGrab}
            src={imgSrc}
            alt={alt}
            styles={styles}
            loading={lazy ? 'lazy' : undefined}
        />
    );
};
