import useEditor from 'client/widget-components/customHooks/useEditor';
import { SNIPCART_EVENTS } from 'client/widget-components/ecom/useSnipcartApi';
import { ProductEntity } from 'client/widget-components/ecom/stores/types';
import { getWindowInBrowser } from 'client/RuntimeWindow';
import { doOnSnipcartReady } from 'client/widget-components/ecom/stores/utils';
import { logger } from '@shared-services/log-service';

const { isInPreviewOrEditor } = useEditor();

export function prepareAndSendGAItemViewEvent(product: ProductEntity) {
    if (!window?.runtime || isInPreviewOrEditor()) {
        return;
    }

    const sendViewItemEvent = () => {
        sendGAEvent('view_item', product.price, getViewItemPayload(product));
    };

    if (window.Snipcart?.store.getState().session.settings.currency) {
        sendViewItemEvent();
    } else {
        doOnSnipcartReady(() => {
            window.Snipcart?.events.on(
                SNIPCART_EVENTS.SNIPCART_INITIALIZED,
                () => {
                    sendViewItemEvent();
                }
            );
        });
    }
}

function getNameFromProduct(product: ProductEntity) {
    try {
        const variationChoices = product.selected_options_values
            ? JSON.parse(product.selected_options_values)
            : {};

        if (Object.keys(variationChoices).length == 0) return product.name;

        const spaceSeparatedSelectedChoices = `${Object.values(
            variationChoices
        ).join(' ')}`;
        return `${product.name} ${spaceSeparatedSelectedChoices}`;
    } catch (error) {
        logger.error({
            message: `Error getting name from product`,
            error,
        });
        return product.name;
    }
}

function getViewItemPayload(product: ProductEntity) {
    let name = getNameFromProduct(product);

    return {
        currency: window
            .Snipcart!.store.getState()
            .session.settings.currency.toUpperCase(),
        items: [
            {
                item_id: product.itemId, //GA4
                item_name: name, //GA4
                id: product.itemId, //UA
                name, //UA
                price: product.price,
                description: product.description,
            },
        ],
    };
}

function sendGAEvent(event: string, value: number, payload: any) {
    window?.runtime?.tagManagerAPI.sendGAEvent({
        category: event,
        action: event,
        value: value,
        siteAlias: getWindowInBrowser()?.Parameters?.SiteAlias,
        payload: payload,
    });
}
