import { Div } from 'client/widget-components/basicComponents';
import Text, {
    TextDomTag,
} from 'client/widget-components/basicComponents/Text';
import React from 'react';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import { localizationService } from '@shared-services/localization-service';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { ProductState } from 'client/widget-components/ecom/stores/ProductState';
import { PRODUCT_ONE_TIME_PAYMENT_PLAN_ID } from 'client/widget-components/ecom/consts';

const { str } = localizationService;

interface OmnibusLabelProps {
    styles?: ResponsiveStylesProp;
    productState: ProductState;
}

function LowestPrice({ productState, styles }: OmnibusLabelProps) {
    const currentProduct =
        productState.selectedVariation ||
        productState.productData.variations[0];
    const lowestPrices = currentProduct?.lowest_prices;
    if (!lowestPrices) {
        return null;
    }
    const selectedPlan =
        productState.selectedPaymentPlan?.id ||
        PRODUCT_ONE_TIME_PAYMENT_PLAN_ID;
    const currentPlanLowestPrice = lowestPrices.find(
        (price) =>
            (price.plan_id || PRODUCT_ONE_TIME_PAYMENT_PLAN_ID) === selectedPlan
    );
    return (
        <Div
            styles={styles}
            data-grab='product-lowest-price'
            data-auto='product-lowest-price'
        >
            {currentPlanLowestPrice && (
                <Text
                    tag={TextDomTag.paragraph}
                    data-auto={`low_price_${currentPlanLowestPrice.displayed_price}`}
                    styles={lowestPriceStyles}
                >
                    {str('ui.runtimessr.productPrice.omnibus.last.price', {
                        period: currentPlanLowestPrice.period.toString(),
                        displayedPrice: currentPlanLowestPrice.displayed_price,
                    })}
                </Text>
            )}
        </Div>
    );
}

const lowestPriceStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        margin: 0,
    },
};

export default LowestPrice;
