import { makeAutoObservable } from 'mobx';
import { localizationService } from '@shared-services/localization-service';
import { CustomizationType, CustomizationValue } from './types';

const { str } = localizationService;

class CustomizationsState {
    list: CustomizationType[] = [];
    values: Record<string, CustomizationValue> = {};
    errors: Record<string, string> = {};
    validationSchema: Record<
        string,
        Array<(value: CustomizationValue) => boolean | string>
    > = {};

    constructor(customizations: CustomizationType[] = []) {
        makeAutoObservable(this, {
            validationSchema: false,
            isEmpty: false,
            isValid: false,
        });
        this.validationSchema = buildValidationSchema(customizations);
        this.list = customizations;
    }

    updateValue = (customizationId: string, value: CustomizationValue) => {
        this.values[customizationId] = value;
        if (this.errors[customizationId]) {
            delete this.errors[customizationId];
        }
    };

    validate = (customizationId: string) => {
        const currentValue = this.values[customizationId];

        for (const rule of this.validationSchema[customizationId]) {
            const error = rule(currentValue);

            if (typeof error === 'string') {
                this.errors[customizationId] = error;
                return;
            }
        }
    };

    validateAll = () => {
        this.list.forEach((customization) => {
            this.validate(customization.id);
        });
    };

    isEmpty = () => {
        return this.list.length === 0;
    };

    isValid = () => {
        return Object.keys(this.errors).length === 0;
    };
}

const RULES = {
    required: (value?: CustomizationValue) =>
        !!value || str('ui.runtimessr.productCustomizations.errors.required'),
};

function buildValidationSchema(customizations: CustomizationType[]) {
    return customizations.reduce((acc, { id, mandatory }) => {
        if (!acc[id]) {
            acc[id] = [];
        }

        if (mandatory) {
            acc[id].push(RULES.required);
        }

        return acc;
    }, {} as Record<string, Array<(value: CustomizationValue) => boolean | string>>);
}

export default CustomizationsState;
