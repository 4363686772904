import React, { ReactNode } from 'react';
import { Label, Span } from 'client/widget-components/basicComponents';
import { Input } from 'client/widget-components/basicComponents/Input/Input';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import Text, {
    TextDomTag,
} from 'client/widget-components/basicComponents/Text';

type ComponentDataGrabs = {
    labelDataGrab?: string;
    outerCircleDataGrab?: string;
    innerCircleDataGrab?: string;
};
export interface RadioButtonStyles {
    labelWrapperStyles?: ResponsiveStylesProp;
    labelStyles?: ResponsiveStylesProp;
    inputStyles?: ResponsiveStylesProp;
    inputIconStyles?: ResponsiveStylesProp;
}
export interface RadioButtonProps extends RadioButtonStyles {
    label?: ReactNode;
    labelTag?: TextDomTag;
    dataGrabs?: ComponentDataGrabs;
    value: string | number;
    selectedValue?: string | number;
    onChange: (value: string) => void;
    disabled?: boolean;
}

const RadioButton = ({
    label,
    labelTag = TextDomTag.paragraph,
    onChange,
    value,
    selectedValue,
    labelStyles,
    labelWrapperStyles,
    inputStyles,
    inputIconStyles,
    dataGrabs,
    ...rest
}: RadioButtonProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
    };
    const checked = selectedValue === value;
    return (
        <Label
            styles={[radioBtnWrapperDefaultStyles, labelWrapperStyles]}
            {...rest}
        >
            <Input
                type='radio'
                onChange={handleChange}
                value={value}
                checked={checked}
                styles={{
                    [RuntimeMediaQuery.COMMON]: {
                        display: 'none',
                    },
                }}
            />
            <Span
                data-grab={
                    dataGrabs?.outerCircleDataGrab || 'radio-outer-circle'
                }
                styles={[radioBtnDefaultStyles, inputStyles]}
            >
                <Span
                    data-grab={
                        dataGrabs?.innerCircleDataGrab || 'radio-inner-circle'
                    }
                    styles={[getRadioBtnInnerStyles(checked), inputIconStyles]}
                />
            </Span>
            <Text
                styles={[radioLabelDefaultStyles, labelStyles]}
                tag={labelTag}
                data-grab={dataGrabs?.labelDataGrab || 'radio-input-label'}
            >
                {label || value}
            </Text>
        </Label>
    );
};

const radioBtnWrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        gap: '8px',
        color: '#313131',
    },
};

const radioLabelDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        textTransform: 'capitalize',
        flex: 1,
        margin: 0,
        display: 'grid',
        alignItems: 'center',
    },
};

const radioBtnDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '18px',
        height: '18px',
        border: '1px solid #ced6d9',
        borderRadius: '50%',
        boxSizing: 'border-box',
        backgroundColor: '#fff',
        flexShrink: 0,
    },
};

function getRadioBtnInnerStyles(checked: boolean): ResponsiveStylesStructured {
    return {
        [RuntimeMediaQuery.COMMON]: {
            display: 'block',
            opacity: checked ? 1 : 0,
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: '#30373a',
            flexShrink: 0,
        },
    };
}

export default RadioButton;
