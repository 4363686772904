import useEditor from 'client/widget-components/customHooks/useEditor';
import React, { FC } from 'react';

/** This component is intentionally using native dom element. As it is a fallback on failure and we want to keep it as simple as possible. */
const ErrorFallback: FC = () => {
    const { insideEditor } = useEditor();

    return (
        <div data-auto='widgets-error-fallback'>
            {insideEditor() && (
                <h4 style={{ fontSize: 24 }}>
                    Something went wrong. We are on it
                </h4>
            )}
        </div>
    );
};

export default ErrorFallback;
