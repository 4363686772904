import React from 'react';
import {
    DeprecatedResponsiveStyles,
    getBreakpointsStyle,
} from 'client/widget-components/responsive-styles/deprecated/styled-components-utils';
import styled, { CSSProperties } from 'styled-components';

interface SvgIconProps {
    dataAuto: string;
    className: string;
    svgMarkup?: string;
    styles?: DeprecatedResponsiveStyles | CSSProperties;
    onClick: () => void;
}

type SvgIconPropsNoDataAuto = Omit<SvgIconProps, 'dataAuto'>;

const CartIconStyled = styled.span<SvgIconPropsNoDataAuto>`
    display: grid;
    width: 16px;
    ${({ styles = {} }: Pick<SvgIconProps, 'styles'>) =>
        getBreakpointsStyle(styles)}
    svg {
        fill: currentColor;
    }
`;

function SvgIcon({
    className,
    dataAuto,
    svgMarkup,
    styles,
    onClick,
}: SvgIconProps) {
    return (
        <CartIconStyled
            onClick={onClick}
            className={className}
            data-auto={dataAuto}
            styles={styles}
            dangerouslySetInnerHTML={{
                __html: svgMarkup || getDefaultMarkup(),
            }}
        />
    );
}

function getDefaultMarkup() {
    return `<svg version="1.1" id='cart-widget' xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           viewBox="0 0 52 54" style="enable-background:new 0 0 52 54;" xml:space="preserve">
            <path clip-rule="evenodd" fill-rule="evenodd"  d="M22.5,2.8c-4.2,0-7.6,3.4-7.6,7.6V14h3.6v-3.5c0-2.2,1.8-4,4-4h7c2.2,0,4,1.8,4,4V14h3.6v-3.5
              c0-4.2-3.4-7.6-7.6-7.6H22.5z M11.5,17.6h29.1c2.8,0,5.1,2.3,5.1,5.1v19.8c0,2.8-2.3,5.1-5.1,5.1H11.5c-2.8,0-5.1-2.3-5.1-5.1V22.7
              C6.4,19.8,8.6,17.6,11.5,17.6z M2.8,22.7c0-4.8,3.9-8.7,8.7-8.7h29.1c4.8,0,8.7,3.9,8.7,8.7v19.8c0,4.8-3.9,8.7-8.7,8.7H11.5
              c-4.8,0-8.7-3.9-8.7-8.7V22.7z"/>
            </svg>`;
}

export default SvgIcon;
