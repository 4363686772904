import React from 'react';
import { MediaBaseData } from '../../../CommonModelTypes';
import { BulletsPaginationProps } from '../PaginationProps';
import BulletsBase from '../BulletsBase';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import { Div } from 'client/widget-components/basicComponents';

export interface PaginationThumbsExtraProps {
    thumbList: Array<MediaBaseData>;
}

export type ThumbsPaginationProps = BulletsPaginationProps &
    PaginationThumbsExtraProps;

const Thumbs: React.FC<ThumbsPaginationProps> = ({
    thumbList,
    styles,
    cursor,
    ...otherProps
}) => {
    return (
        <Div styles={styles?.container}>
            <BulletsBase
                bulletsList={thumbList.map((thumbData, index) => {
                    const isActive = index === cursor ? ' active' : '';
                    return {
                        styles: [
                            getThumbStyles(thumbData?.imgSrc),
                            index === cursor
                                ? [
                                      buttonSelectedDefaultStyle,
                                      styles?.buttonThumbActive,
                                  ]
                                : undefined,
                        ],
                        domAttributes: {
                            'data-auto': `pagination-button-thumb ${index}${isActive}`,
                            'data-grab': `pagination-button-thumb${isActive}`,
                        },
                    };
                })}
                dataGrab='pagination-container thumbs-container'
                {...otherProps}
                styles={{
                    buttonBullet: [buttonDefaultStyle, styles?.buttonThumb],
                    container: [
                        innerContainerDefaultStyles,
                        styles?.thumbsContainer,
                        thumbList.length > 3
                            ? undefined
                            : noScrollContainerStyles,
                    ],
                }}
            />
        </Div>
    );
};

const innerContainerDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        gap: 20,
    },
};

function getThumbStyles(src?: string): ResponsiveStylesStructured {
    return {
        [RuntimeMediaQuery.COMMON]: {
            backgroundImage: `url(${src})`,
        },
    };
}

const noScrollContainerStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.MOBILE]: {
        justifyContent: 'center',
    },
};

const buttonDefaultStyle: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        aspectRatio: '1 / 1',
        pointerEvents: 'auto',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderWidth: 3,
        borderColor: 'transparent',
        borderStyle: 'solid',
    },
};

const buttonSelectedDefaultStyle: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        borderColor: 'black',
    },
};

export default Thumbs;
