import { useRef, TouchEventHandler } from 'react';

interface UseSliderSwipeProps {
    onSwipeForward(): void;
    onSwipeBack(): void;
}

const minSwipeDistance = 40;

export const useSliderSwipe = ({
    onSwipeForward,
    onSwipeBack,
}: UseSliderSwipeProps) => {
    const touchstartX = useRef(0);

    const handleTouchStart: TouchEventHandler<HTMLElement> = (e) => {
        touchstartX.current = e.changedTouches[0].screenX;
    };

    const handleTouchEnd: TouchEventHandler<HTMLElement> = (e) => {
        const currentTouchX = e.changedTouches[0].screenX;
        const currentSwipeDistance = Math.abs(
            currentTouchX - touchstartX.current
        );

        if (currentSwipeDistance <= minSwipeDistance) {
            return;
        }

        if (currentTouchX > touchstartX.current) {
            onSwipeForward();
        } else {
            onSwipeBack();
        }
    };

    return {
        handleTouchStart,
        handleTouchEnd,
    };
};
