import React from 'react';
import { observer } from 'mobx-react';
import { ProductViewType } from 'client/widget-components/ecom/stores/types';
import ProductsStore from 'client/widget-components/ecom/stores/ProductsStore';
import { mapProductViewToProduct } from 'client/widget-components/ecom/stores/utils';

interface Props extends Record<string, unknown> {
    productView: ProductViewType;
}

export const ecomObserver = (WrappedComponent: React.ComponentType<any>) => {
    return function (props: Props) {
        const product = mapProductViewToProduct(props.productView);
        const productState = ProductsStore.addProduct(product);
        if (!productState) {
            return null;
        }
        const ObserverComponent = observer(WrappedComponent);
        return <ObserverComponent {...props} productState={productState} />;
    };
};
