import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import React, { FC } from 'react';
import { Div } from '..';

interface Props {
    name: string;
    dataGrab?: string;
    styles: {
        wrapper?: ResponsiveStylesProp;
        icon?: ResponsiveStylesProp;
    };
}

export const Icon: FC<Props> = ({ name, dataGrab, styles }) => {
    return (
        <Div styles={styles.wrapper}>
            <Div
                styles={[styles.icon]}
                data-grab={dataGrab}
                className={name}
                aria-hidden='true'
            />
        </Div>
    );
};
