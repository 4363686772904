import { PaginationType } from 'client/widget-components';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import { featureFlags } from '@shared-services/feature-flags';
import { SliderLayout, sliderLayouts } from '../sliderCommonTypes';

interface SlideShowLayoutPaginationStyles {
    container?: ResponsiveStylesStructured;
}

export type SlideShowLayoutPaginationStylesPerLayout = Record<
    SliderLayout,
    SlideShowLayoutPaginationStyles
>;
export type SliderLayoutsPaginationOverrides = Partial<
    Record<PaginationType, Partial<SlideShowLayoutPaginationStylesPerLayout>>
>;
const slideShowLayoutPaginationStylesInner = (
    numOfSlideSlots: number
): SliderLayoutsPaginationOverrides => {
    const imageToFillAvailableSpace = featureFlags.getBoolean(
        'runtime.ssr.slider.image.fillAvailableSpace.enabled',
        false
    );
    return {
        [PaginationType.BULLETS]: {
            [sliderLayouts.LAYOUT_2]: {
                container: {
                    [RuntimeMediaQuery.MOBILE]:
                        numOfSlideSlots < 3
                            ? {}
                            : {
                                  position: 'relative',
                              },
                },
            },
            ...(imageToFillAvailableSpace && {
                [sliderLayouts.LAYOUT_5]: {
                    container: {
                        [RuntimeMediaQuery.MOBILE]: {
                            position: 'relative',
                        },
                    },
                },
            }),
        },
        [PaginationType.ARROWS]: {
            [sliderLayouts.LAYOUT_2]: {
                container: {
                    [RuntimeMediaQuery.MOBILE]: {
                        width: `${100 * numOfSlideSlots}%`,
                        height: '100%',
                        position: 'relative',
                        left: 'unset', //reset left property
                    },
                },
            },
            [sliderLayouts.LAYOUT_5]: {
                container: {
                    [RuntimeMediaQuery.MOBILE]: {
                        width: `${100 * numOfSlideSlots}%`,
                        height: '100%',
                        position: 'relative',
                        left: 'unset', //reset left property
                    },
                },
            },
        },
    };
};
export const getSlideShowLayoutPaginationStylesOverrides = ({
    paginationType,
    slotsInFrame,
    layout,
}: {
    paginationType: PaginationType | undefined;
    slotsInFrame: number;
    layout: SliderLayout;
}): SlideShowLayoutPaginationStyles | undefined => {
    const flag = featureFlags.getBoolean(
        `runtime.ssr.ssrSlider.multiplePaginationPerLayout.enabled`,
        false
    );
    if (!flag || !paginationType) {
        return {};
    }
    return slideShowLayoutPaginationStylesInner(slotsInFrame)?.[
        paginationType
    ]?.[layout];
};
