import React from 'react';
import styled from 'styled-components';
import {
    DeprecatedResponsiveStyles,
    getBreakpointsStyle,
    SPECIFICITY_WRAP,
} from '../../responsive-styles/deprecated/styled-components-utils';

enum LinkType {
    popup = 'popup',
}

/**
 * @deprecated Simply use Record<string,string> as shape of attributes returned by the editor link picker.
 * It's better this way since the domAttributes that provide the link picker functionality is the responsibility of the link picker, and since it's not pure MVC.
 */
export interface LinkProps {
    href?: string;
    raw_url?: string;
    runtime_url?: string;
    popup_target?: string;
    file?: boolean;
    type?: LinkType;
    rel?: string;
    link_type?: string;
    blog_post_id?: string;
    'data-dm-multisize-attr'?: string;
    'data-image-url'?: string;
    styles?: DeprecatedResponsiveStyles | React.CSSProperties;
    hoverStyles?: DeprecatedResponsiveStyles | React.CSSProperties;
    className?: string;
    currentPage?: boolean;
}

const defaultLinkProps = {
    href: '#',
};

const StyleLink = styled.a<LinkProps>`
    ${SPECIFICITY_WRAP} {
        ${(props: LinkProps) => getBreakpointsStyle(props?.styles)}
        &:hover {
            ${(props: LinkProps) => getBreakpointsStyle(props?.hoverStyles)}
        }
    }
`;

/**
 * @deprecated use LinkFunctionalityWrapper
 */
const withLink = <Props extends object>(
    WrappedComponent: React.ComponentType<Props>,
    linkProps: LinkProps = defaultLinkProps
) => {
    return (props: any) => (
        <StyleLink {...linkProps}>
            <WrappedComponent {...(props as Props)} />
        </StyleLink>
    );
};

export default withLink;
