import React, { FC } from 'react';

import { PaginationStyles } from './PaginationProps';
import { Div } from 'client/widget-components/basicComponents';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { StyledButton } from 'client/widget-components/basicComponents/StyledButton';

interface BulletsListItem {
    styles: Array<ResponsiveStylesProp | undefined>;
    domAttributes?: React.ButtonHTMLAttributes<HTMLButtonElement> & {
        'data-grab': string;
    };
}

interface Props {
    styles?: Pick<PaginationStyles, 'container' | 'buttonBullet'>;
    goToCursor: (x: number) => void;
    dataGrab?: string;
    bulletsList: BulletsListItem[];
}

const BulletsBase: FC<Props> = ({
    goToCursor,
    styles,
    dataGrab = 'pagination-container',
    bulletsList,
}) => {
    return (
        <Div
            styles={[defaultContainerStyle, styles?.container]}
            data-auto='pagination-bullets-base-container'
            data-grab={dataGrab}
        >
            {bulletsList.map(
                (
                    { styles: buttonSpecificStyles, domAttributes = {} },
                    index
                ) => {
                    const {
                        'data-grab': dataGrab = 'pagination-button-bullet',
                        ...rest
                    } = domAttributes;

                    return (
                        <StyledButton
                            data-grab={dataGrab}
                            {...rest}
                            styles={[
                                defaultButtonStyle,
                                styles?.buttonBullet,
                                buttonSpecificStyles,
                            ]}
                            key={index}
                            onClick={() => {
                                goToCursor(index);
                            }}
                            aria-label={`go to slide ${index + 1}`}
                        />
                    );
                }
            )}
        </Div>
    );
};

const defaultContainerStyle: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
};

const defaultButtonStyle: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        backgroundColor: 'transparent',
    },
};

export default BulletsBase;
