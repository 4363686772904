import { ChangeEvent, useCallback, useState } from 'react';
import { logger } from '@shared-services/log-service';
import { SEARCH_VALUE_MIN_LENGTH } from './consts';
import { Collection, CollectionItem, CollectionsToSearch } from './types';
import { getCollectionAPI } from './collectionService';
import { debounce } from 'client/utils';

export const useCollectionSearch = ({
    collectionsToSearch,
}: {
    collectionsToSearch: CollectionsToSearch;
}) => {
    const [inputValue, setInputValue] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [collections, setCollections] = useState<Collection[]>(() =>
        getInitialCollectionsState(collectionsToSearch)
    );

    const sendRequestDebounce = useCallback(
        debounce(async (value: string) => {
            try {
                const api = await getCollectionAPI();

                if (value.length < SEARCH_VALUE_MIN_LENGTH || !api) {
                    setIsLoading(false);
                    setCollections(
                        getInitialCollectionsState(collectionsToSearch)
                    );
                    return;
                }

                logger.info({
                    message: 'User searches in the collection search widget',
                    tags: ['runtimeCollectionSearch'],
                    searchValue: value,
                });

                const res = await Promise.all(
                    collectionsToSearch.map(({ name }) => {
                        return api
                            .storeData(name)
                            .pageSize(50)
                            .search(value)
                            .get();
                    })
                );

                setCollections((state) =>
                    state.map((collection, index) => {
                        const values = res[index]?.values as CollectionItem[];
                        return {
                            ...collection,
                            items: values || [],
                            page: res[index]?.page || {},
                        };
                    })
                );
            } catch (e) {
                logger.warn({
                    message: 'Error in collection search widget on search',
                    tags: ['__new-runtime__'],
                });
            }

            setIsLoading(false);
        }, 500),
        []
    );

    const fetchMore = async (collectionName: string) => {
        const pageData = collections.find(
            (collection) => collection.name === collectionName
        )?.page;

        if (
            isLoading ||
            !pageData ||
            pageData.pageNumber + 1 === pageData.totalPages
        ) {
            return;
        }

        setIsLoading(true);

        try {
            const api = await getCollectionAPI();

            if (!api) {
                setIsLoading(false);
                return;
            }

            const res = await api
                .storeData(collectionName)
                .pageSize(50)
                .pageNumber(pageData.pageNumber + 1)
                .search(inputValue)
                .get();

            setCollections((state) =>
                state.map((collection) => {
                    if (collection.name !== collectionName) {
                        return collection;
                    }
                    const moreItems = res?.values as CollectionItem[];
                    return {
                        ...collection,
                        items: [...collection.items, ...moreItems],
                        page: res?.page,
                    };
                })
            );
        } catch (e) {
            logger.warn({
                message: 'Error in collection search widget on fetch more',
                tags: ['__new-runtime__'],
            });
        }

        setIsLoading(false);
        logger.info(
            `Fetch more items in the collection search widget, page ${
                pageData.pageNumber + 1
            }`
        );
    };

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputValue(value);
        setIsLoading(true);
        sendRequestDebounce(value.trim());
    };

    const onInputFocus = () => {
        setIsDropdownOpen(true);
    };

    const clearSearchState = () => {
        setInputValue('');
        setCollections(getInitialCollectionsState(collectionsToSearch));
    };

    return {
        inputValue,
        isDropdownOpen,
        isLoading,
        collections,
        onInputChange,
        onInputFocus,
        clearSearchState,
        fetchMore,
    };
};

const getInitialCollectionsState = (collectionsToSearch: CollectionsToSearch) =>
    collectionsToSearch.map(({ name, path }) => ({
        name,
        path,
        items: [],
        page: { pageNumber: 0, totalPages: 0 },
    }));
