export const customClassNames = {
    atomsWrapper: 'atoms-wrapper',
    bookerContainer: 'booker-container',
    eventMetaCustomClassNames: {
        eventMetaContainer: 'event-meta-container',
        eventMetaTitle: 'event-meta-title',
        eventMetaTimezoneSelect: 'event-meta-timezone-select',
    },
    datePickerCustomClassNames: {
        datePickerContainer: 'date-picker-container',
        datePickerTitle: 'date-picker-title',
        datePickerDays: 'date-picker-days',
        datePickerDate: 'date-picker-date',
        datePickerDatesActive: 'date-picker-dates-active',
        datePickerToggle: 'date-picker-toggle',
    },
    availableTimeSlotsCustomClassNames: {
        availableTimeSlotsContainer: 'available-time-slots-container',
        availableTimeSlotsHeaderContainer:
            'available-time-slots-header-container',
        availableTimeSlotsTitle: 'available-time-slots-title',
        availableTimeSlotsTimeFormatToggle:
            'available-time-slots-time-format-toggle',
        availableTimes: 'available-times',
    },
};
