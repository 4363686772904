import { getWindowInBrowser } from '../../RuntimeWindow';
import { CollectionsAPI } from 'client/types';

let collectionAPI: CollectionsAPI | undefined;

const loadCollectionAPI = async () => {
    collectionAPI = await getWindowInBrowser()?.dmAPI?.loadCollectionsAPI?.();
};

export const getCollectionAPI = async (): Promise<
    CollectionsAPI | undefined
> => {
    if (!collectionAPI) {
        await loadCollectionAPI();
    }
    return collectionAPI;
};
