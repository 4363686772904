import { CSSProperties } from 'react';
import { DeprecatedWidgetStyles } from 'client/widget-components/responsive-styles/deprecated/styled-components-utils';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';

export type OldStyles = { [key: string]: CSSProperties };

export type OldBPStyles = DeprecatedWidgetStyles;

export type NewBPStyles = { [key: string]: ResponsiveStylesProp };

export type AnyStyleStructure =
    | OldStyles
    | OldBPStyles
    | NewBPStyles
    | { [key: string]: string };

export enum StylesType {
    NO_BP = 'no-breakpoints',
    OLD_BP = 'old-breakpoints',
    NEW_BP = 'new-styles',
}

export type MigrateStylesProps = {
    stylesInput?: AnyStyleStructure;
    migrationStyleKeys?: { [key: string]: string };
    monitorKey?: string;
};
