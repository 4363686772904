import styled from 'styled-components';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { toCSSObject } from 'client/widget-components/responsive-styles/toCSSObject';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';

interface Props {
    styles?: ResponsiveStylesProp;
}

export const StyledButton = styled.button<Props>(({ styles }) =>
    toCSSObject([buttonNormalize, styles], true)
);

const buttonNormalize: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        padding: 'unset',
        backgroundColor: 'unset',
        border: 'unset',
        cursor: 'pointer',
    },
};
