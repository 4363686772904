import { getWindowInBrowser } from 'client/RuntimeWindow';

type SSRToEditorMessage = {
    type:
        | 'widget-rendered-following-props-change'
        | 'widget-props-store-initial-props-updated';
    widgetId: string;
};

export function postToEditor(_message: SSRToEditorMessage) {
    const message = {
        ..._message,
        type: `ssr-${_message.type}`,
    };
    return getWindowInBrowser().parent.postMessage(message, '*');
}
