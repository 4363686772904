import React, { FC } from 'react';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import { context } from '@shared-services/context-service';
import {
    mergeResponsiveStyles,
    nestResponsiveStyles,
    ResponsiveStylesProp,
} from 'client/widget-components/responsive-styles/responsiveStylesService';
import { CollectionItem } from '../types';
import StyledLink from 'client/widget-components/basicComponents/StyledLink';
import { Img, Span } from 'client/widget-components/basicComponents';

interface SearchItemProps {
    href?: string;
    styles?: {
        dropdownItemHover?: ResponsiveStylesProp;
    };
    grabId?: string;
    item: CollectionItem;
}

export const SearchItem: FC<SearchItemProps> = ({
    href = '',
    styles,
    grabId,
    item,
}) => {
    return (
        <StyledLink
            styles={[
                itemDefaultStyles,
                nestResponsiveStyles(
                    mergeResponsiveStyles([
                        itemHoverDefaultStyles,
                        styles?.dropdownItemHover,
                    ]),
                    {
                        pseudoSelector: 'hover',
                    }
                ),
            ]}
            domAttrs={{
                href,
                onClick: (event) => {
                    if (context.isEditor) {
                        event.preventDefault();
                    }
                },
            }}
            grabId={grabId}
        >
            <Img styles={imgDefaultStyles} src={item.data.image} alt='' />
            <Span styles={nameDefaultStyles}>{item.data.name}</Span>
            <Span>{item.data.displayed_price}</Span>
        </StyledLink>
    );
};

const itemDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        textDecoration: 'none',
        color: 'inherit',
        padding: '8px 40px',
        borderTop: '1px solid #E2E2E2',
    },
    [RuntimeMediaQuery.MOBILE]: {
        padding: '8px 20px',
    },
};

const itemHoverDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        background: '#F4F4F4',
    },
};

const imgDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: 48,
        height: 48,
        objectFit: 'cover',
        borderRadius: 3,
    },
};

const nameDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        flexGrow: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
};
