import styled from 'styled-components';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { toCSSObject } from 'client/widget-components/responsive-styles/toCSSObject';

interface Props {
    styles?: ResponsiveStylesProp;
    increaseSpecificity?: boolean;
}

export const Input = styled.input<Props>(({ styles, increaseSpecificity }) =>
    toCSSObject([normalizeStyles, styles], increaseSpecificity)
);

const normalizeStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        padding: 'unset',
        backgroundColor: 'unset',
        border: '0 solid #000000',
    },
};
