export const TEXT_FIELD_DATA_GRABS = {
    textFieldWrapperDataGrab: 'product-customizations-text-field',
    labelDataGrab: 'product-customizations-text-field-label',
    textareaDataGrab: 'product-customizations-text-field-textarea',
    textareaPlaceholderDataGrab:
        'product-customizations-text-field-placeholder',
    counterDataGrab: 'product-customizations-text-field-counter',
};

export const MAX_TEXT_FIELD_LENGTH = 320;
