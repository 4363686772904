import { logger } from '@shared-services/log-service';
import { IconProps, OldIconProps } from '../types';
import { ButtonStyles } from 'client/widget-components/CommonModelTypes';
import { normalizeStyles } from 'client/widgets/common/styles/migration/stylesMigration';

const migrationStyleKeys: { [key: string]: string } = {
    button: 'root',
    buttonHover: 'rootHover',
    disabledButton: 'rootDisabled',
    textHover: 'rootHoverText',
    iconHover: 'rootHoverIcon',
    disabledIcon: 'iconDisabled',
};

export function getWidgetStyles(styles?: object): ButtonStyles {
    try {
        return normalizeStyles({
            monitorKey: 'addtocart',
            stylesInput: styles,
            migrationStyleKeys,
        }) as ButtonStyles;
    } catch (e) {
        logger.error({
            message: `Failed to migrate old styles structure`,
            errorMessage: e?.toString(),
            tags: ['AddToCart style migration'],
        });
        throw e;
    }
}

export function getWidgetIconProps(iconProps?: OldIconProps | IconProps) {
    if (iconProps) {
        if ('show-icon' in iconProps) {
            return {
                showIcon: iconProps['show-icon'],
                iconName: iconProps['selected-icon']?.classname,
            };
        }
        return {
            showIcon: iconProps.showIcon,
            iconName: iconProps.iconName,
        };
    }
    return { showIcon: false, iconName: '' };
}
