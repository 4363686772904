import { useEffect, useRef, useState } from 'react';
import { getWindowInBrowser } from '../../../RuntimeWindow';

export const useIsVisible = () => {
    const elementRef = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const windowInBrowser = getWindowInBrowser();

        if (elementRef.current && windowInBrowser) {
            const observer = new windowInBrowser.IntersectionObserver(
                ([entry]) => {
                    setIsVisible(entry.isIntersecting);
                }
            );

            observer.observe(elementRef.current);
            return () => observer.disconnect();
        }
    }, [elementRef.current]);

    return {
        elementRef,
        isVisible,
    };
};
