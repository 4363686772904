import { Label } from 'client/widget-components/basicComponents';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';

export interface FieldLabelProps
    extends Omit<ComponentProps<typeof Label>, 'children'> {
    text: React.ReactNode;
    dataGrab?: string;
    dataAuto?: string;
    required?: boolean;
    styles?: ResponsiveStylesProp;
}

function FieldLabel({
    text,
    dataGrab,
    dataAuto = 'field-label',
    styles,
    required,
    ...restProps
}: FieldLabelProps) {
    return (
        <Label
            styles={[labelDefaultStyles, styles]}
            data-grab={dataGrab}
            data-auto={dataAuto}
            {...restProps}
        >
            {text} {required && <Asterisk>*</Asterisk>}
        </Label>
    );
}

const labelDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        textTransform: 'capitalize',
        margin: 0,
        boxSizing: 'border-box',
    },
};

export const Asterisk = styled.span`
    color: #e33e3b;
`;

export default FieldLabel;
