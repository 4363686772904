import React from 'react';

const useWrapperStyle = (elementRef: React.RefObject<HTMLElement>) => {
    const setStyle = (styles: React.CSSProperties) => {
        if (elementRef.current === null) return;
        Object.assign(elementRef.current.style, styles);
    };
    const resetStyle = (properties: (keyof React.CSSProperties)[]) => {
        if (elementRef.current === null) return;
        properties.forEach((property) => {
            Object.assign(elementRef.current!.style, { [property]: null });
        });
    };
    return { setStyle, resetStyle };
};
export default useWrapperStyle;
