import React, { FC } from 'react';

export const SearchInputIcon: FC = () => {
    return (
        <svg
            width='100%'
            viewBox='0 0 26 26'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2.625 10.4375C2.625 6.12277 6.12277 2.625 10.4375 2.625C14.7522 2.625 18.25 6.12277 18.25 10.4375C18.25 14.7522 14.7522 18.25 10.4375 18.25C6.12277 18.25 2.625 14.7522 2.625 10.4375ZM10.4375 0.75C5.08724 0.75 0.75 5.08724 0.75 10.4375C0.75 15.7878 5.08724 20.125 10.4375 20.125C12.775 20.125 14.9192 19.2971 16.5927 17.9186L23.5246 24.8504C23.8907 25.2165 24.4843 25.2165 24.8504 24.8504C25.2165 24.4843 25.2165 23.8907 24.8504 23.5246L17.9186 16.5927C19.2971 14.9192 20.125 12.775 20.125 10.4375C20.125 5.08724 15.7878 0.75 10.4375 0.75Z'
                fill='currentColor'
            />
        </svg>
    );
};
