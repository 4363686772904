import React, { CSSProperties } from 'react';
import {
    CSSObject,
    CSSProperties as CSSStyledProperties,
    CSSPseudos,
} from 'styled-components';
import {
    breakpointsRange,
    DeprecatedRuntimeBreakpoints,
    RuntimeBreakpoint,
} from '@duda-co/runtime-breakpoints';

/** @deprecated */
export const SPECIFICITY_WRAP = '#dm &&';

const WRAPPER_START: string = '\n#dm && {\n';
const WRAPPER_END: string = '}';

/**
 * @deprecated
 * This methods allow us to wrap the tag literal given to StyleCompoennt with
 * custom selectors. We use that to increase the selector specificity.
 */
export function wrapStyledLiterals(
    strings: TemplateStringsArray,
    ...values: any[]
): [TemplateStringsArray, any[]] {
    const ret = Array.isArray(strings)
        ? ([
              WRAPPER_START,
              ...strings,
              WRAPPER_END,
          ] as unknown as TemplateStringsArray)
        : strings;

    return [ret, values];
}

/** @deprecated */
export type DeprecatedResponsiveStyles = Partial<
    Record<DeprecatedRuntimeBreakpoints, React.CSSProperties>
>;

type DeprecatedStyleRules = Record<string, DeprecatedResponsiveStyles>;
/** @deprecated */
export interface DeprecatedWidgetStyles {
    rules?: DeprecatedStyleRules;
}

const allBreakpointsIds = [
    ...Object.values(RuntimeBreakpoint),
    ...Object.values(DeprecatedRuntimeBreakpoints),
] as string[];

/**
 *
 * @deprecated
 */
export function getBreakpointsStyle(
    styles: DeprecatedResponsiveStyles | React.CSSProperties = {}
): string | CSSObject {
    let result: string | CSSObject = '';
    // Maintain backwards compatibility

    if (
        Object.keys(styles).some((styleKey) =>
            allBreakpointsIds.includes(styleKey)
        )
    ) {
        for (const [formFactor, cssProperties] of Object.entries(styles)) {
            result += getResponsiveStatement(
                formFactor as DeprecatedRuntimeBreakpoints,
                cssProperties
            );
        }
    } else {
        result = styles as CSSObject;
    }
    return result;
}

function getMqObject(
    breakpoint: DeprecatedRuntimeBreakpoints | RuntimeBreakpoint,
    cssProperties: CSSStyledProperties | CSSPseudos
) {
    const bpRange = breakpointsRange[breakpoint];

    if (!bpRange.maxWidth && !bpRange.minWidth) {
        return cssProperties;
    } else {
        const mqRange = Object.entries(bpRange)
            .map(
                ([property, size]) =>
                    `(${getWidthCondition(property as any, size as number)})`
            )
            .join(' and ');

        return {
            [`@media ${mqRange}`]: cssProperties,
        };
    }
}

/**
 * @deprecated
 * gets duda Breakpoint objects, and returns a nested css object that styled components supports
 */
export function getResponsiveCssObject(
    styles: DeprecatedResponsiveStyles = {},
    increaseSpecificity: boolean = false
): Record<string, any> {
    let styleWithMediaQueries = {};

    Object.entries(styles).forEach(([bp, rules]) => {
        const newMQ = getMqObject(bp as any, rules);
        Object.assign(styleWithMediaQueries, newMQ);
    });

    if (increaseSpecificity) {
        return {
            [SPECIFICITY_WRAP]: styleWithMediaQueries,
        };
    }

    return styleWithMediaQueries;
}

function getWidthCondition(property: 'maxWidth' | 'minWidth', value: number) {
    return `${property === 'maxWidth' ? 'max-width' : 'min-width'}: ${value}px`;
}

function getResponsiveStatement(
    breakpoint: DeprecatedRuntimeBreakpoints | RuntimeBreakpoint,
    cssProperties: CSSProperties
) {
    const bpRange = breakpointsRange[breakpoint];
    const styles = getStyleStrFromCssProps(cssProperties);

    if (!bpRange.maxWidth && !bpRange.minWidth) {
        return `${styles}\n`;
    } else {
        const conditions = Object.entries(bpRange)
            .map(
                ([property, size]) =>
                    `(${getWidthCondition(property as any, size as number)})`
            )
            .join(' and ');

        return `@media ${conditions} {\n\t${styles}}\n`;
    }
}

function camelToKabab(str: string) {
    return str.replace(/[A-Z]/g, (c) => {
        return '-' + c.toLowerCase();
    });
}

function getStyleStrFromCssProps(cssProperties: CSSProperties) {
    let str = '';
    for (const [cssProp, value] of Object.entries(cssProperties)) {
        str += `\t${camelToKabab(cssProp)}: ${value};\n`;
    }
    return str;
}

/** @deprecated */
export function upgradeStyles(widgetStyles: DeprecatedWidgetStyles = {}) {
    let result: DeprecatedWidgetStyles = widgetStyles;
    const shouldUpdateStyles = shouldUpgradeStyles(widgetStyles);

    if (shouldUpdateStyles) {
        // Go over each key in the widgetStyles and add a "default" level to it
        const upgradedRules: DeprecatedStyleRules = {};
        for (const [key, value] of Object.entries(widgetStyles)) {
            upgradedRules[key] = {
                default: value,
            } as DeprecatedResponsiveStyles;
        }

        result = {
            rules: upgradedRules,
        };
    }

    return result;
}

function shouldUpgradeStyles(widgetStyles: DeprecatedWidgetStyles): boolean {
    let result = true;

    if (['rules'].some((key) => key in widgetStyles)) {
        return false;
    }

    return result;
}
