import { useEffect, useMemo, useState } from 'react';
import { useEditorContext } from 'client/infra/renderers/editorState';
import { hasProductGalleryOnPage } from './components/utils';
import useFilterSortLogic from './components/useFilterSortLogic';
import { FilterSortExtensionData } from './components/utils/types';
import { context } from '@shared-services/context-service';

export default function useFilterSortWidget(props: FilterSortExtensionData) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const { isInEditor } = useEditorContext();

    const {
        filterableFields = [],
        sortableFields,
        collectionValue,
        isSortSectionHidden,
        hasNativeStore,
    } = props;

    const filterSortLogic = useFilterSortLogic(collectionValue, {
        isSortSectionHidden,
        sortableFields,
    });

    const allOptionsAreDisabled = useMemo(() => {
        const isContentHidden =
            props.isFilterSectionHidden && props.isSortSectionHidden;
        if (isContentHidden) {
            return true;
        }
        const enabledItem = [
            ...(props.isFilterSectionHidden ? [] : filterableFields),
            ...(props.isSortSectionHidden ? [] : sortableFields),
        ].some((item) => !item.disabled);
        return !enabledItem;
    }, [filterableFields, sortableFields]);

    useEffect(() => {
        const shouldShowWarning = !hasNativeStore || !hasProductGalleryOnPage();
        setShowWarning(!!isInEditor && shouldShowWarning);
        if (isInEditor && isMenuOpen) {
            setIsMenuOpen(false);
        }
    }, [isInEditor]);

    const onBtnClick = () => {
        if (!context.isEditor) {
            setIsMenuOpen((open) => !open);
        }
    };

    const shouldRenderMenu =
        (isMenuOpen || !!props.menuPanelMode) &&
        filterSortLogic &&
        !allOptionsAreDisabled;

    return {
        filterSortLogic,
        onBtnClick,
        showWarning,
        setIsMenuOpen,
        shouldRenderMenu,
    };
}
