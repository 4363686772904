import { Div, Span } from 'client/widget-components/basicComponents';
import Text, {
    TextDomTag,
} from 'client/widget-components/basicComponents/Text';
import Portal from 'client/widget-components/components/Portal';
import { CloseIcon } from 'client/widget-components/components/icons';
import { nestResponsiveStyles } from 'client/widget-components/responsive-styles/responsiveStylesService';
import React from 'react';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import { localizationService } from '@shared-services/localization-service';
import FiltersList from './filter';
import SortItemsList from './sort';
import { FilterSortLogicResult } from './useFilterSortLogic';
import { menuSelectors, textSelectors } from './utils';
import {
    useFilterSortContext,
    useFilterSortStyles,
} from './utils/filterSortContext';
import { FilterSortExtensionData } from './utils/types';

const { str } = localizationService;

export interface FilterSortMenuProps
    extends Omit<FilterSortExtensionData, 'hasNativeStore' | 'widgetId'> {
    styleWrapperSelector: string;
    widgetId: string;
    setIsMenuOpen: (v: boolean) => void;
}
function FilterSortMenu({
    props,
    filterSortLogic,
}: {
    props: FilterSortMenuProps;
    filterSortLogic: FilterSortLogicResult;
}) {
    const { menuStyle, textStyle } = useFilterSortStyles();
    const { inDesignMode, isPremiumWidget } = useFilterSortContext();

    const {
        sortableFields,
        filterableFields,
        buttonText,
        isFilterSectionHidden,
        styleWrapperSelector,
        setIsMenuOpen,
    } = props;

    const {
        currentSelection,
        onChangeFilter,
        showSortSection,
        onChangeSort,
        clearCollectionValues,
        selectedCount,
    } = filterSortLogic;

    const filterSectionIsShown =
        isPremiumWidget && (!isFilterSectionHidden || inDesignMode);
    const sortSectionIsShown = showSortSection || inDesignMode;
    return (
        <Portal>
            <Div
                styles={[wrapperDefaultStyles, menuStyle?.wrapper]}
                data-auto='filter-sort-floating-menu'
                data-grab={styleWrapperSelector}
            >
                <Text
                    tag={TextDomTag.h3}
                    data-grab={textSelectors.displayName}
                    styles={[titleDefaultStyles, textStyle?.displayName]}
                >
                    <Span styles={labelDefaultStyle}>{buttonText}</Span>
                    <CloseIcon
                        styles={[closeBtnDefaultStyles, menuStyle?.closeIcon]}
                        dataGrab={menuSelectors.closeIcon}
                        onClick={() => setIsMenuOpen(false)}
                    />
                </Text>

                {selectedCount > 0 && (
                    <Text
                        tag={TextDomTag.h4}
                        styles={[clearAllDefaultStyles, textStyle?.title]}
                        domAttrs={{ onClick: clearCollectionValues }}
                        data-auto='clear-filters'
                    >
                        <Span>{str('widget.filtersort.clear-all')}</Span>
                        <CloseIcon
                            size={8}
                            styles={{
                                [RuntimeMediaQuery.COMMON]: {
                                    marginInlineStart: '4px',
                                    display: 'grid',
                                    alignItems: 'center',
                                },
                            }}
                        />
                    </Text>
                )}
                <Div
                    className='dmNewParagraph'
                    styles={[
                        contentDefaultStyles,
                        contentScrollbarDefaultStyles,
                        menuStyle?.list,
                    ]}
                    data-grab={menuSelectors.list}
                >
                    {sortSectionIsShown && (
                        <SortItemsList
                            name={str('widget.filtersort.sort-by.title')}
                            values={sortableFields}
                            onChange={onChangeSort}
                            currentSelection={{
                                sortDirection:
                                    currentSelection.sortDirection || 'asc',
                                fieldId: currentSelection.sortBy || '',
                            }}
                        />
                    )}
                    {filterSectionIsShown && (
                        <FiltersList
                            values={filterableFields}
                            currentSelection={currentSelection.filters}
                            onChange={onChangeFilter}
                        />
                    )}
                </Div>
            </Div>
        </Portal>
    );
}

const wrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        cursor: 'auto',
        height: '100%',
        position: 'fixed',
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: '#ffffff',
        color: '#000000',
        zIndex: 999,
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #000',
        width: '418px',
        padding: '40px',
        maxWidth: '100%',
        minWidth: '300px',
        boxSizing: 'border-box',
    },
};

const titleDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBlockStart: '0',
        marginBlockEnd: '26px',
        lineHeight: '29px',
        width: '100%',
    },
};

const labelDefaultStyle: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        flex: 1,
        margin: 0,
    },
};
const clearAllDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        lineHeight: 'normal',
        textTransform: 'uppercase',
        marginBlockStart: 'calc(50px - 26px)',
        marginBlockEnd: '50px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        cursor: 'pointer',
    },
};
const closeBtnDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        cursor: 'pointer',
        color: '#333',
        display: 'flex',
        padding: '10px',
        margin: '-10px',
        boxSizing: 'border-box',
    },
};

const contentDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        overflowY: 'auto',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        padding: 0,
    },
};

const contentScrollbarDefaultStyles: ResponsiveStylesStructured =
    nestResponsiveStyles(
        {
            [RuntimeMediaQuery.COMMON]: {
                width: '10px',
                backgroundColor: 'transparent',
            },
        },
        {
            innerSelector: '&::-webkit-scrollbar',
        }
    );

export default FilterSortMenu;
