import TextField from 'client/widget-components/components/TextField';
import { TextCustomizationType } from 'client/widget-components/ecom/stores/types';
import React, { FC, memo } from 'react';
import { ProductCustomizationsStyles } from '../RuntimeProductCustomizationsTypes';
import {
    Label,
    TextareaWithCustomPlaceholder,
} from './TextFieldsCustomComponents';
import { MAX_TEXT_FIELD_LENGTH, TEXT_FIELD_DATA_GRABS } from './constants';

interface RuntimeProductCustomizationTextFieldProps
    extends TextCustomizationType {
    value?: string;
    error?: string | null;
    styles?: ProductCustomizationsStyles;
    maxLength?: number;
    onChange: (fieldId: string, value: string) => void;
    validateField: (fieldId: string) => void;
}

const RuntimeProductCustomizationTextField: FC<
    RuntimeProductCustomizationTextFieldProps
> = ({
    id,
    label,
    hint,
    title,
    value = '',
    mandatory = false,
    maxLength = MAX_TEXT_FIELD_LENGTH,
    styles,
    error,
    onChange,
    validateField,
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(id, event.target.value);
    };

    const handleBlur = () => {
        validateField(id);
    };

    return (
        <TextField
            label={label}
            placeholder={hint}
            error={error}
            styles={{
                label: styles?.inputLabel,
                counter: styles?.inputCharacterCounter,
            }}
            dataAuto='product-customizations-text-field'
            dataGrabs={TEXT_FIELD_DATA_GRABS}
            labelElement={
                <Label
                    styles={{
                        inputLabel: styles?.inputLabel,
                        inputCharacterCounter: styles?.inputCharacterCounter,
                    }}
                    required={mandatory}
                    label={label}
                    valueLength={value.length}
                    maxLength={maxLength}
                />
            }
            textareaElement={
                <TextareaWithCustomPlaceholder
                    name={title}
                    id={id}
                    value={value}
                    placeholder={hint}
                    required={mandatory}
                    maxLength={maxLength}
                    error={!!error}
                    onBlur={handleBlur}
                    styles={{
                        textarea: styles?.input,
                        placeholder: styles?.inputPlaceholder,
                    }}
                    data-grab={TEXT_FIELD_DATA_GRABS?.textareaDataGrab}
                    increaseSpecificity
                    onChange={handleChange}
                />
            }
        />
    );
};

export default memo(RuntimeProductCustomizationTextField);
