import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { toCSSObject } from 'client/widget-components/responsive-styles/toCSSObject';
import React from 'react';
import styled from 'styled-components';

export enum TextDomTag {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
    paragraph = 'p',
    span = 'span',
    div = 'div',
}

export function getDomTagByValue(key: string): TextDomTag | undefined {
    return TextDomTag[key as keyof typeof TextDomTag];
}

export interface TextProps {
    tag?: TextDomTag;
    styles?: ResponsiveStylesProp;
    domAttrs?: React.HTMLAttributes<HTMLElement>;
    'data-auto'?: string;
    children?: React.ReactNode;
    grabId?: string;
    className?: string;
}

const Tag = styled.div<{
    styles?: ResponsiveStylesProp;
}>((props) => toCSSObject(props.styles, true));

const Text: React.FC<TextProps> & {
    selector: typeof Tag;
} = ({ tag, domAttrs, grabId, ...otherProps }) => {
    return (
        <Tag
            as={tag}
            data-auto={grabId}
            data-grab={grabId}
            {...domAttrs}
            {...otherProps}
        />
    );
};

Text.selector = Tag;

export default Text;
