import { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import { runtimeBreakpointToMediaQueryRange } from '@duda-co/responsive-styles/runtimeBreakpointToMediaQueryRange';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import { type RuntimeBreakpointExplicit } from '@duda-co/responsive-styles/types/RuntimeMediaQuery';

import type { CSSObject, CSSProperties } from 'styled-components';

import {
    mergeResponsiveStyles,
    ResponsiveStylesProp,
} from './responsiveStylesService';

export const SPECIFICITY_WRAP = '#dm#dm#dm &&';

function getWidthCondition(property: 'maxWidth' | 'minWidth', value: number) {
    return `${property === 'maxWidth' ? 'max-width' : 'min-width'}: ${value}px`;
}

function getMqObject(
    breakpoint: RuntimeBreakpointExplicit,
    cssProperties: CSSProperties
) {
    const bpRange = runtimeBreakpointToMediaQueryRange[breakpoint];

    let conditions: string;

    if (!bpRange.maxWidth && !bpRange.minWidth) {
        conditions = 'all';
    } else {
        conditions = Object.entries(bpRange)
            .map(
                ([property, size]) =>
                    `(${getWidthCondition(property as any, size as number)})`
            )
            .join(' and ');
    }

    return {
        [`@media ${conditions}`]: cssProperties,
    };
}

const mobileBreakpoints = {
    main: RuntimeMediaQuery.MOBILE,
    implicit: RuntimeMediaQuery.MOBILE_IMPLICIT,
};
const tabletBreakpoints = {
    main: RuntimeMediaQuery.TABLET,
    implicit: RuntimeMediaQuery.TABLET_IMPLICIT,
};

interface BreakpointWithImplicit {
    main: RuntimeMediaQuery;
    implicit: RuntimeMediaQuery;
}

function mergeImplicit(
    styles: ResponsiveStylesStructured = {},
    breakpointProps: BreakpointWithImplicit
) {
    const implicitRules = styles[breakpointProps.implicit];
    const mainRules = styles[breakpointProps.main];

    return (implicitRules || mainRules) && { ...implicitRules, ...mainRules };
}

const pick = <V, S extends string>(
    obj: Record<string, V>,
    keys: S[]
): Record<S, V> =>
    Object.fromEntries(
        keys.filter((key) => key in obj).map((key) => [key, obj[key]])
    ) as any;

/**
 *
 * @deprecated
 * entity is only used in ms-runtime so it is being moved there.
 */
export function convertDudasBreakpointsStylesToCSSObject(
    styles: ResponsiveStylesStructured = {},
    increaseSpecificity: boolean = false
): CSSObject {
    const mergedImplicitStyles: Record<
        RuntimeBreakpointExplicit,
        CSSProperties | undefined
    > = {
        ...pick(styles, [RuntimeMediaQuery.COMMON, RuntimeMediaQuery.DESKTOP]),

        [RuntimeMediaQuery.TABLET]: mergeImplicit(styles, tabletBreakpoints),
        [RuntimeMediaQuery.MOBILE]: mergeImplicit(styles, mobileBreakpoints),
    };

    let styleWithMediaQueries = {};

    (
        Object.entries(mergedImplicitStyles) as Array<
            [RuntimeBreakpointExplicit, CSSProperties]
        >
    ).forEach(([siteBp, cssProps]) => {
        if (cssProps) {
            const newMQ = getMqObject(siteBp, cssProps);
            Object.assign(styleWithMediaQueries, newMQ);
        }
    });

    if (increaseSpecificity) {
        return {
            [SPECIFICITY_WRAP]: styleWithMediaQueries,
        };
    }

    return styleWithMediaQueries;
}

export function toCSSObject(
    styles?: ResponsiveStylesProp,
    increaseSpecificity?: boolean
) {
    const merged = mergeResponsiveStyles(styles || []);

    return convertDudasBreakpointsStylesToCSSObject(
        merged,
        increaseSpecificity
    );
}
