import React, { FC } from 'react';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import { context } from '@shared-services/context-service';
import { AccordionLayoutProps, AccordionStyles } from '../accordionCommonTypes';
import AccordionItem from './AccordionItem';

const data = {
    title: 'Title or Question',
    desc: 'Describe the item or answer the question so that site visitors who are interested get more information. You can emphasize this text with bullets, italics or bold, and add links.',
    button: {
        text: 'Button',
    },
    showButton: true,
    showMedia: true,
    media: {
        lazy: false,
        type: 'IMAGE',
        imgSrc: 'https://du-cdn.cdn-website.com/duda_website/images/home/hp_performance.png?v=17',
    },
} as const;

export const AccordionItemEditorFallback: FC<
    Partial<AccordionLayoutProps> & { styles?: AccordionStyles }
> = ({ styles, arrowDirection, arrowType, hasTitleIcons }) => {
    return (
        <>
            {context.isEditor && (
                <AccordionItem
                    {...data}
                    styles={{
                        ...styles,
                        itemContainer: [
                            styles?.itemContainer,
                            {
                                [RuntimeMediaQuery.COMMON]: {
                                    display: 'none',
                                },
                            },
                        ],
                    }}
                    arrowDirection={arrowDirection}
                    arrowType={arrowType}
                    hasTitleIcons={hasTitleIcons}
                    index={0}
                    isOpen
                    disableTransition
                />
            )}
        </>
    );
};
