import {
    ResponsiveStylesProp,
    nestResponsiveStyles,
} from 'client/widget-components/responsive-styles/responsiveStylesService';
import { SliderLayoutProps } from '../sliderCommonTypes';

const hideStyles = nestResponsiveStyles(
    {
        common: {
            visibility: 'hidden',
            opacity: '0',
        },
    },
    {
        innerSelector: '[data-grab="pagination-container"]',
    }
);

const displayNeverStyles = [
    {
        common: {
            paddingInline: 0,
        },
    },
    nestResponsiveStyles(
        {
            common: {
                display: 'none',
            },
        },
        {
            innerSelector: '[data-grab="pagination-container"]',
        }
    ),
];

const displayOnHoverStyles = nestResponsiveStyles(
    {
        common: {
            visibility: 'visible',
            opacity: '1',
            transition: 'opacity 300ms ease',
        },
    },
    {
        pseudoSelector: 'hover',
        innerSelector: '[data-grab="pagination-container"]',
    }
);

export const paginationStylesByShowType = (
    showType: SliderLayoutProps['paginationShow']
) => {
    const styles: ResponsiveStylesProp = [];
    switch (showType) {
        case 'onHover': {
            styles.push(hideStyles, displayOnHoverStyles);
            break;
        }
        case 'never': {
            styles.push(hideStyles, displayNeverStyles);
            break;
        }
        default: {
            break;
        }
    }

    return styles;
};
