import Text, {
    TextDomTag,
} from 'client/widget-components/basicComponents/Text';
import Textarea, {
    TextareaProps,
} from 'client/widget-components/basicComponents/Textarea';
import { Asterisk } from 'client/widget-components/components/FieldLabel';
import BaseCharCounter from 'client/widget-components/components/TextField/components/BaseCharCounter';
import { textareaDefaultStyles } from 'client/widget-components/components/TextField/styles';
import {
    ResponsiveStylesProp,
    mergeResponsiveStyles,
    nestResponsiveStyles,
    pickResponsiveStyles,
} from 'client/widget-components/responsive-styles/responsiveStylesService';
import React, { CSSProperties, useMemo } from 'react';
import styled from 'styled-components';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import { TEXT_FIELD_DATA_GRABS } from './constants';

interface LabelProps {
    label: string;
    valueLength: number;
    maxLength: number;
    required?: boolean;
    styles: {
        inputLabel?: ResponsiveStylesProp;
        inputCharacterCounter?: ResponsiveStylesProp;
    };
}

// Label should inherit global H5 styles
export const Label = ({
    styles,
    label,
    required,
    valueLength,
    maxLength,
}: LabelProps) => (
    <Text tag={TextDomTag.h5} styles={labelWrapperDefaultStyles}>
        <Text
            tag={TextDomTag.span}
            styles={[labelDefaultStyles, styles.inputLabel]}
            data-grab={TEXT_FIELD_DATA_GRABS.labelDataGrab}
            data-auto='product-customizations-text-field-label'
        >
            {label}
            {required && <Asterisk>*</Asterisk>}
        </Text>
        <BaseCharCounter
            valueLength={valueLength}
            maxLength={maxLength}
            tag={TextDomTag.span}
            styles={[counterDefaultStyles, styles.inputCharacterCounter]}
            dataGrab={TEXT_FIELD_DATA_GRABS.counterDataGrab}
            dataAuto='product-customizations-text-field-counter'
        />
    </Text>
);

interface TextareaWithCustomPlaceholderProps
    extends Omit<TextareaProps, 'styles'> {
    styles?: {
        textarea?: ResponsiveStylesProp;
        placeholder?: ResponsiveStylesProp;
    };
    error?: boolean;
}

// Placeholder should inherit global Paragraph styles `div.dmNewParagraph p`
export const TextareaWithCustomPlaceholder = (
    props: TextareaWithCustomPlaceholderProps
) => {
    const { textareaStyles, placeholderStyles } = useMemo(() => {
        const _textareaStyles = mergeResponsiveStyles([
            textareaDefaultStyles,
            props.error ? invalidTextareaStyles : {},
            nestResponsiveStyles(
                {
                    [RuntimeMediaQuery.COMMON]: {
                        opacity: 0,
                    },
                },
                {
                    innerSelector: '&::placeholder',
                }
            ),
            props.styles?.textarea,
        ]);
        return {
            textareaStyles: _textareaStyles,
            placeholderStyles: mergeResponsiveStyles([
                pickResponsiveStyles(_textareaStyles, propertiesSetToPick),
                hintDefaultStyles,
                props.styles?.placeholder,
            ]),
        };
    }, [props.styles?.textarea, props.styles?.placeholder, props.error]);

    return (
        <TextAreaWrapper className='dmNewParagraph'>
            <Textarea {...props} styles={textareaStyles} />
            {!props.value && (
                <Text
                    styles={placeholderStyles}
                    data-grab={
                        TEXT_FIELD_DATA_GRABS.textareaPlaceholderDataGrab
                    }
                    tag={TextDomTag.paragraph}
                >
                    {props.placeholder}
                </Text>
            )}
        </TextAreaWrapper>
    );
};

const labelWrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 0,
        width: '100%',
    },
};

const labelDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        margin: 0,
    },
};

const counterDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        margin: 0,
    },
};

const TextAreaWrapper = styled.div`
    height: 86px;
    position: relative;
    margin: 0;
    padding: 0;
`;

const invalidTextareaStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        borderColor: '#E33E3B',
    },
};

const commonPlaceholderStyles: CSSProperties = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    boxSizing: 'border-box',
    pointerEvents: 'none',
    margin: 0,
    borderColor: 'transparent',
    overflow: 'hidden',
    color: 'rgba(201, 202, 205, 1)',
};
const hintDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: commonPlaceholderStyles,
    [RuntimeMediaQuery.MOBILE]: commonPlaceholderStyles,
    [RuntimeMediaQuery.TABLET]: commonPlaceholderStyles,
    [RuntimeMediaQuery.DESKTOP]: commonPlaceholderStyles,
    [RuntimeMediaQuery.MOBILE_IMPLICIT]: commonPlaceholderStyles,
    [RuntimeMediaQuery.TABLET_IMPLICIT]: commonPlaceholderStyles,
};

const propertiesSetToPick: Set<keyof CSSProperties> = new Set([
    'border',
    'borderWidth',
    'borderTopWidth',
    'borderBottomWidth',
    'borderLeftWidth',
    'borderRightWidth',

    'borderRadius',
    'borderTopLeftRadius',
    'borderTopRightRadius',
    'borderBottomLeftRadius',
    'borderBottomRightRadius',

    'padding',
    'paddingTop',
    'paddingRight',
    'paddingBottom',
    'paddingLeft',
    'paddingInline',
    'paddingBlock',
    'paddingInlineStart',
    'paddingInlineEnd',
    'paddingBlockStart',
    'paddingBlockEnd',
]);
