import { EventsService } from './eventsServiceTypes';
import { getWindowInBrowser } from '../../../RuntimeWindow';

export const eventsService: EventsService = {
    dispatch: (type, source, detail) => {
        const browserWindow = getWindowInBrowser();
        const customEvent = new CustomEvent<typeof detail>(
            `${type}-${source}`,
            {
                detail,
            }
        );
        return browserWindow.document.dispatchEvent(customEvent);
    },

    listen(type, source, callback) {
        getWindowInBrowser().document.addEventListener(
            `${type}-${source}`,
            callback as any
        );
        return () =>
            getWindowInBrowser().document.removeEventListener(
                `${type}-${source}`,
                callback as any
            );
    },
};
