import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    FilterableField,
    FilterIDS,
    FilterServiceData,
    FilterSortExtensionData,
    SortableField,
} from './utils/types';
import { useFilterSortService } from './utils/filterSortService';
import { logger } from '@shared-services/log-service';
export default function useFilterSortLogic(
    collectionValue: string,
    config: Pick<
        FilterSortExtensionData,
        'isSortSectionHidden' | 'sortableFields'
    >
): FilterSortLogicResult | null {
    const [currentSelection, setCurrentSelection] = useState<FilterServiceData>(
        {
            filters: {},
            sortBy: '',
            sortDirection: 'asc',
        }
    );

    const filterService = useFilterSortService(collectionValue);

    useEffect(() => {
        async function updateCurrentSelection() {
            if (filterService) {
                try {
                    return await filterService.onCollectionValueChange(
                        collectionValue,
                        (selection) => {
                            setCurrentSelection(selection);
                        }
                    );
                } catch (error) {
                    logger.error({
                        message: `Failed to execute filterService.onCollectionValueChange`,
                        errorMessage: error?.toString(),
                        tags: ['FilterSortMenu', 'updateCurrentSelection'],
                    });
                }
            }
        }
        let disposer = () => {};

        updateCurrentSelection().then((reactionDisposer) => {
            if (reactionDisposer) {
                disposer = reactionDisposer;
            }
        });
        return disposer;
    }, [filterService]);

    const changeFilter = useCallback(
        (filterValue: FilterableField) => {
            filterService?.setCollectionFilter(
                filterValue.fieldId,
                filterValue
            );
        },
        [filterService]
    );

    const changeSort = useCallback(
        (selectedSort: SortableField) => {
            filterService?.setCollectionSort(
                selectedSort.fieldId,
                selectedSort.sortDirection
            );
        },
        [filterService]
    );

    const clearCollectionValues = useCallback(() => {
        filterService?.clearCollectionValues();
    }, [filterService]);

    const selectedCount = useMemo(() => {
        let count = 0;
        const { sortBy, filters } = currentSelection;
        const priceSelection = filters[FilterIDS.PRICE]?.selectedValues;
        const categoriesSelection =
            filters[FilterIDS.CATEGORY_ID]?.selectedValues;
        if (sortBy) {
            count++;
        }
        if (priceSelection) {
            count++;
        }
        if (Array.isArray(categoriesSelection)) {
            count += categoriesSelection.length;
        }
        return count;
    }, [currentSelection]);

    if (!filterService) {
        return null;
    }

    const showSortSection =
        !config.isSortSectionHidden &&
        config.sortableFields.some((item) => !item.disabled);

    return {
        currentSelection,
        onChangeFilter: changeFilter,
        showSortSection,
        onChangeSort: changeSort,
        clearCollectionValues,
        selectedCount,
    };
}

export interface FilterSortLogicResult {
    currentSelection: FilterServiceData;
    onChangeFilter: (filterValue: FilterableField) => void;
    showSortSection: boolean;
    onChangeSort: (selectedSort: SortableField) => void;
    clearCollectionValues: () => void;
    selectedCount: number;
}
