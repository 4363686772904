import { ChangeEvent, MouseEvent, useCallback, useState } from 'react';
import { POINTS } from '../types';
import useDebouncedCallback from 'client/widget-components/customHooks/useDebouncedCallback';
import { RangeInputsProps } from './RangeSliderInputs';
import { getWindowInBrowser } from '../../../../RuntimeWindow';

function useRangeSliderLogic({
    rangeValues,
    minRange = 1,
    ...props
}: RangeInputsProps) {
    const [activeSlider, setActiveSlider] = useState<POINTS | null>(null);
    const [trackDimensions, setTrackDimensions] = useState<DOMRect | null>(
        null
    );
    const [mouseIsDown, setMouseIsDown] = useState(false);
    const [sliderDirection, setSliderDirection] = useState('ltr');

    const trackRefCB = useCallback((node: HTMLDivElement) => {
        if (node) {
            setTrackDimensions(node?.getBoundingClientRect());
            const computedStyle = getWindowInBrowser().getComputedStyle(node);
            setSliderDirection(computedStyle.getPropertyValue('direction'));
        }
    }, []);

    const getSliderValue = useCallback(
        (event: MouseEvent<HTMLElement>) => {
            if (trackDimensions) {
                const { width: trackWidth, left, right } = trackDimensions;
                const clickPos =
                    sliderDirection === 'rtl'
                        ? right - event.clientX
                        : event.clientX - left;

                const valueRange = props.max - props.min;
                return Math.round(
                    (clickPos / trackWidth) * valueRange + props.min
                );
            }
            return null;
        },
        [trackDimensions, sliderDirection]
    );

    const { start: startValue, end: endValue } = rangeValues;

    const changeStartValue = (newStartValue: number) => {
        const startValueBelowEnd = Math.min(newStartValue, endValue - minRange);
        const startValueAboveMin = Math.max(startValueBelowEnd, props.min);
        props.onChange({ start: startValueAboveMin, end: endValue });
    };
    const changeEndValue = (newEndValue: number) => {
        const endValueAboveStart = Math.max(newEndValue, startValue + minRange);
        const endValueBelowMax = Math.min(endValueAboveStart, props.max);
        props.onChange({ start: startValue, end: endValueBelowMax });
    };

    const handleActiveTrack = (event: MouseEvent<HTMLElement>) => {
        const newSliderValue = getSliderValue(event);
        if (newSliderValue) {
            const isCloserToStart =
                Math.abs(rangeValues.start - newSliderValue) <
                Math.abs(rangeValues.end - newSliderValue);

            const relativeSlider = isCloserToStart ? POINTS.START : POINTS.END;
            if (activeSlider !== relativeSlider) {
                setActiveSlider(relativeSlider);
            }
        }
    };

    const onMouseMove = useDebouncedCallback({
        callback: handleActiveTrack,
        msToWait: 10,
    });

    return {
        trackRefCB,
        activeSlider,
        wrapperEvents: {
            onMouseMove: (e: MouseEvent<HTMLDivElement>) => {
                !mouseIsDown && onMouseMove(e);
            },
            onMouseDown: () => {
                setMouseIsDown(true);
            },
            onMouseUp: () => {
                setActiveSlider(null);
                setMouseIsDown(false);
            },
        },
        onStartInputChange: (event: ChangeEvent<HTMLInputElement>) => {
            changeStartValue(+event.target.value);
        },
        onEndInputChange: (event: ChangeEvent<HTMLInputElement>) => {
            changeEndValue(+event.target.value);
        },
    };
}

export default useRangeSliderLogic;
