import { CollectionsAPI, UserInfo } from 'client/types';

/**
 * to be used only when running in browser. For example - in event handlers.
 */
export function getWindowInBrowser() {
    if (!window) {
        throw new Error("Window object isn't available");
    }
    return window as unknown as RuntimeWindow;
}

export interface RuntimeWindow extends Window {
    Parameters: {
        isInEditor?: boolean;
        SiteAlias?: string;
        IsSiteMultilingual?: boolean;
        defaultLang?: string;
    };
    $: {
        Editor: {
            uiStateListener: any;
        };
    };
    dmAPI: {
        loadCollectionsAPI: () => Promise<CollectionsAPI>;
        getCurrentEnvironment: () => string;
        getLoggedInUser: () => Promise<UserInfo>;
        getSiteCurrentLocale: () => string;
    };
    IntersectionObserver: new (
        callback: (entries: IntersectionObserverEntry[]) => void
    ) => IntersectionObserver;
}
