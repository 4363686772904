import React from 'react';
export default function () {
    return (
        <svg
            width='14'
            height='7'
            viewBox='0 0 15 9'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path d='M0.604736 1L7.60474 8L14.6047 1' stroke='currentColor' />
        </svg>
    );
}
