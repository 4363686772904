import React from 'react';

const initialContextData = { isInEditor: globalThis?.Parameters?.isInEditor };
const EditorContext = React.createContext(initialContextData);

export function useEditorContext() {
    return React.useContext(EditorContext);
}

export function EditorStateContext({
    children,
}: {
    children: React.ReactNode;
}) {
    const editorState = useEditorState();
    return (
        <EditorContext.Provider value={editorState}>
            {children}
        </EditorContext.Provider>
    );
}

function useEditorState() {
    const [uiStateData, setUiStateData] = React.useState(initialContextData);

    React.useEffect(() => {
        const isInEditor = window?.Parameters?.isInEditor; // indicates editor/preview on first time
        if (!isInEditor) {
            return;
        }

        let dispose: null | (() => void) = null;
        const interval = setInterval(() => {
            const uiStateListener = window.top?.$?.Editor?.uiStateListener;
            if (uiStateListener) {
                clearInterval(interval);
                dispose = uiStateListener(setUiStateData);
            }
        }, 1000);

        return () => {
            dispose?.();
        };
    }, []);

    return uiStateData;
}

declare global {
    var Parameters: { isInEditor?: boolean };
    interface Window {
        $: {
            Editor: {
                uiStateListener: any;
            };
        };
    }
}
