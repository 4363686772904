import { Div } from 'client/widget-components/basicComponents';
import Text from 'client/widget-components/basicComponents/Text';
import React from 'react';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import { ProductState } from 'client/widget-components/ecom/stores/ProductState';
import { localizationService } from '@shared-services/localization-service';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';

const { str } = localizationService;

interface PriceTaxLabelProps {
    styles?: ResponsiveStylesProp;
    productState: ProductState;
}

function PriceTaxLabel({ productState, styles }: PriceTaxLabelProps) {
    const currentProduct =
        productState.selectedVariation ||
        productState.productData.variations[0];

    const vatPercentage = currentProduct?.tax_percentage?.toString();
    const showTaxes =
        productState.productData.is_tax_included?.toString() === 'true' &&
        vatPercentage;

    if (!showTaxes) {
        return null;
    }
    return (
        <Div styles={[styles, vatAreaStyles]} data-auto='price-vat'>
            <Text data-auto='vat-msg'>
                {str('ui.runtimessr.productPrice.omnibus.vat', {
                    vatPercentage,
                })}
            </Text>
            <Text data-auto='vat-desc'>
                {str(
                    'ui.runtimessr.productPrice.omnibus.total.price.might.change'
                )}
            </Text>
        </Div>
    );
}

const vatAreaStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        marginBlockEnd: '8px',
    },
};
export default PriceTaxLabel;
