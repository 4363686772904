import { PaginationType } from 'client/widget-components';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import { featureFlags } from '@shared-services/feature-flags';
import {
    SliderLayoutsPaginationOverrides,
    sliderLayouts,
} from './sliderCommonTypes';

const sliderLayoutsPaginationOverridesInner: SliderLayoutsPaginationOverrides =
    {
        [PaginationType.ARROWS]: {
            [sliderLayouts.LAYOUT_1]: {
                styles: {
                    pagination_container: {
                        [RuntimeMediaQuery.COMMON]: {
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            top: 0,
                        },
                    },
                },
            },
            [sliderLayouts.LAYOUT_2]: {
                styles: {
                    container: {
                        [RuntimeMediaQuery.MOBILE]: {
                            paddingInline: 48,
                        },
                    },
                    slidesContainer: {
                        [RuntimeMediaQuery.MOBILE]: {
                            overflow: 'hidden',
                        },
                    },
                    pagination_buttonArrow: {
                        [RuntimeMediaQuery.COMMON]: {
                            padding: 16,
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            padding: 16, //override default
                        },
                    },
                },
            },
            [sliderLayouts.LAYOUT_3]: {
                styles: {
                    pagination_buttonArrow: {
                        [RuntimeMediaQuery.COMMON]: {
                            padding: 16,
                        },
                    },
                },
            },
            [sliderLayouts.LAYOUT_3_B]: {
                styles: {
                    pagination_buttonArrow: {
                        [RuntimeMediaQuery.COMMON]: {
                            padding: 16,
                        },
                    },
                },
            },
            [sliderLayouts.LAYOUT_5]: {
                styles: {
                    container: {
                        [RuntimeMediaQuery.COMMON]: {
                            paddingInline: 59,
                            position: 'relative',
                            display: 'block',
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            paddingInline: 50,
                            display: 'block',
                        },
                    },
                    slidesContainer: {
                        [RuntimeMediaQuery.MOBILE]: {
                            overflow: 'hidden',
                        },
                    },
                    slide_mediaContainer: {
                        [RuntimeMediaQuery.COMMON]: {
                            height: '57%',
                            minHeight: '57%',
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            height: '59.4%',
                            minHeight: '59.4%',
                        },
                    },
                    pagination_container: {
                        [RuntimeMediaQuery.COMMON]: {
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            top: 0,
                        },
                    },
                    pagination_buttonArrow: {
                        [RuntimeMediaQuery.COMMON]: {
                            paddingInline: 16,
                        },
                    },
                },
            },
            [sliderLayouts.LAYOUT_6]: {
                styles: {
                    container: {
                        [RuntimeMediaQuery.COMMON]: {
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            gap: 24,
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            paddingInline: 50,
                            display: 'block',
                        },
                    },
                    pagination_container: {
                        [RuntimeMediaQuery.COMMON]: {
                            order: 0,
                            flexDirection: 'column',
                            paddingInlineStart: 2,
                        },

                        [RuntimeMediaQuery.MOBILE]: {
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 83,
                        },
                    },
                    pagination_buttonArrow: {
                        [RuntimeMediaQuery.COMMON]: {
                            transform: 'rotate(90deg)',
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            transform: 'rotate(0)',
                        },
                    },
                },
            },
        },
        [PaginationType.BULLETS]: {
            [sliderLayouts.LAYOUT_2]: {
                styles: {
                    container: {
                        [RuntimeMediaQuery.COMMON]: {
                            paddingInline: 0,
                            position: 'relative',
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            paddingInline: 0,
                        },
                    },
                    pagination_container: {
                        [RuntimeMediaQuery.COMMON]: {
                            position: 'absolute',
                            bottom: 24,
                            width: '100%',
                            top: 'auto',
                        },
                    },
                    slidesContainer: {
                        [RuntimeMediaQuery.COMMON]: {
                            height: '87%',
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            height: '87%',
                        },
                    },
                },
            },
            [sliderLayouts.LAYOUT_3]: {
                styles: {
                    container: {
                        [RuntimeMediaQuery.COMMON]: {
                            paddingInline: 0,
                            position: 'relative',
                        },
                        [RuntimeMediaQuery.MOBILE]: {
                            paddingInline: 0,
                        },
                    },
                    pagination_container: {
                        [RuntimeMediaQuery.COMMON]: {
                            position: 'absolute',
                            bottom: 24,
                            left: 0,
                            right: 0,
                            width: '100%',
                            top: 'auto',
                        },
                    },
                },
            },
            [sliderLayouts.LAYOUT_3_B]: {
                styles: {
                    container: {
                        [RuntimeMediaQuery.COMMON]: {
                            paddingTop: 0,
                        },
                        [RuntimeMediaQuery.MOBILE]: {}, //complete styles reset
                    },
                    pagination_container: {
                        [RuntimeMediaQuery.COMMON]: {
                            position: 'absolute',
                            bottom: 24,
                            width: '100%',
                            top: 'auto',
                            left: 0,
                            right: 0,
                        },
                    },
                },
            },
        },
        [PaginationType.ARROWS_AND_THUMBS]: {
            [sliderLayouts.LAYOUT_4]: {
                styles: {
                    container: {
                        [RuntimeMediaQuery.COMMON]: {
                            '& > [data-auto="pagination-arrows"]': {
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                bottom: 0,
                                top: -106,
                            },
                        },
                    },
                },
            },
        },
    };
export const sliderLayoutsPaginationOverrides: () => SliderLayoutsPaginationOverrides =
    () => {
        const flag = featureFlags.getBoolean(
            `runtime.ssr.ssrSlider.multiplePaginationPerLayout.enabled`,
            false
        );
        return flag ? sliderLayoutsPaginationOverridesInner : {};
    };
