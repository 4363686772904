import React, { FC } from 'react';
import { ComponentProps, useAddToCart } from './useAddToCart';
import { ecomObserver } from '@shared-services/ecom-service';
import styled from 'styled-components';
import withErrorBoundary from 'client/widget-components/components/ErrorBoundary/withErrorBoundary';
import { ProductState } from 'client/widget-components/ecom/stores/ProductState';

interface AddToCartProps extends Record<string, unknown> {
    productState: ProductState;
}

const ComponentWrapper = styled.div`
    cursor: auto;
    width: 100%;
    height: 100%;
`;

export default function withAddToCart<
    PureCompProps extends ComponentProps & Record<string, unknown>, // Pure Component's props - should handle all keys from ComponentProps
    WrappedCompProps extends AddToCartProps &
        Omit<PureCompProps, keyof ComponentProps> // HoC output component props - (Pure Component's props + AddToCartProps - ComponentProps)
>(WrappedComponent: FC<PureCompProps>) {
    const BoundedComponent = withErrorBoundary({
        Comp: WrappedComponent,
        componentName: WrappedComponent.name || 'withAddToCart',
        additionalInfoToLog: {
            tag: 'native-ecom',
        },
        logProps: true,
    });
    return ecomObserver((allProps: WrappedCompProps) => {
        const { productState, ...props } = allProps;
        const { productData } = productState;

        const { elementAttributes, componentProps } = useAddToCart({
            product: productData,
            productState: productState,
        });
        if (!productData) {
            return <div />;
        }
        return (
            <ComponentWrapper {...elementAttributes}>
                <BoundedComponent
                    {...(props as PureCompProps)}
                    {...componentProps}
                />
            </ComponentWrapper>
        );
    });
}
