import { RuntimeWidget } from 'client/types';
import withErrorBoundary from 'client/widget-components/components/ErrorBoundary/withErrorBoundary';
import React, { Suspense, lazy, useEffect } from 'react';
import styled from 'styled-components';
import { customClassNames } from './utils';

const loadModule = () => import('@calcom/atoms');

const Booker = lazy(() =>
    loadModule().then((module) => ({ default: module.Booker }))
);

const CalProvider = lazy(() =>
    loadModule().then((module) => ({ default: module.CalProvider }))
);

interface CalBookingProps {
    clientId: string;
    userName: string;
    eventSlug: string;
    localeCode?: any;
    apiUrl?: string;
}

const RuntimeCalBooking: RuntimeWidget = (props: CalBookingProps) => {
    const {
        localeCode = 'en',
        apiUrl = 'https://api.cal.com/v2',
        eventSlug,
        clientId,
        userName,
    } = props;

    useEffect(() => {
        // @ts-ignore
        import('@calcom/atoms/globals.min.css');
    }, []);

    if (!eventSlug || !userName) {
        console.error('Event slug and user name are required');
        return <div>Event slug and user name are required</div>;
    }

    return (
        <CalBookingWrapper data-auto='ssr-cal-booking-widget'>
            <div className='booker-wrapper'>
                <Suspense fallback={<div>Loading Booker...</div>}>
                    <CalProvider
                        clientId={clientId}
                        options={{ apiUrl }}
                        language={localeCode}
                    >
                        <Booker
                            eventSlug={eventSlug}
                            username={userName}
                            customClassNames={customClassNames}
                        />
                    </CalProvider>
                </Suspense>
            </div>
        </CalBookingWrapper>
    );
};

const CalBookingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    --booker-meta-width: 120px;
    .booker-wrapper {
        width: 100%;
        .calcom-atoms {
            .available-time-slots-header-container {
                display: flex;
                flex-wrap: wrap;
            }
            .booker-container {
                display: flex;
                box-sizing: border-box;
                > * {
                    width: auto;
                    flex: 1;
                }
                .event-meta-title {
                    font-size: x-large !important;
                }
            }
        }
    }
`;

export default withErrorBoundary({
    Comp: RuntimeCalBooking,
    logProps: true,
    componentName: 'CalBooking',
    additionalInfoToLog: {
        tag: 'booking',
    },
});
