import { Div } from 'client/widget-components/basicComponents';
import Text, {
    TextDomTag,
} from 'client/widget-components/basicComponents/Text';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import React, { useCallback } from 'react';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import NativeDropdown from './NativeDropdown';
import { DropdownBaseProps } from './types';

const DropdownComponent = ({
    title,
    dropdownPlaceholder,
    styles,
    direction,
    ...dropdownProps
}: DropdownBaseProps) => {
    const refCB = useCallback((node: HTMLElement) => {
        if (node && !node.dir) {
            node.dir = getComputedStyle(node)?.direction || 'ltr';
        }
    }, []);

    return (
        <Div
            styles={wrapperDefaultStyles}
            data-grab='dropdown-wrapper'
            data-auto='dropdown-wrapper'
            className='dropdown-wrapper'
            dir={direction}
            ref={refCB as any}
        >
            {title && (
                <Text
                    tag={TextDomTag.h5}
                    data-auto='dropdown-title'
                    data-grab='dropdown-title'
                    // @ts-ignore
                    className='dropdown-title'
                    styles={[titleDefaultStyles, styles?.title]}
                >
                    {title}
                </Text>
            )}
            <NativeDropdown
                placeholder={dropdownPlaceholder}
                styles={styles?.fields}
                {...dropdownProps}
            />
        </Div>
    );
};

const wrapperDefaultStyles: ResponsiveStylesProp = {
    [RuntimeMediaQuery.COMMON]: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        '*': {
            userSelect: 'none',
        },
    },
};
const titleDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        textAlign: 'start',
        marginBlockEnd: '8px',
        lineHeight: '100%',
        marginTop: '0',
        direction: 'inherit',
    },
};
export default DropdownComponent;
