import { Logger } from 'shared-services/types/log-service';
import { logService } from '@duda-co/host-services';

type MessagePayload = string | Record<string, unknown>;

function extendDataToLog(data: string | object) {
    return typeof data === 'string'
        ? data
        : { url: window.location.href, ...data };
}

export const logger: Logger = new Proxy(logService as unknown as Logger, {
    get: (target, prop: keyof Logger) => (data: MessagePayload) => {
        target[prop](extendDataToLog(data));
    },
});
