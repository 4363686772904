import React, { ChangeEvent, FC, useState } from 'react';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import { Input } from 'client/widget-components/basicComponents/Input/Input';
import {
    mergeResponsiveStyles,
    nestResponsiveStyles,
    ResponsiveStylesProp,
} from 'client/widget-components/responsive-styles/responsiveStylesService';
import { Div } from 'client/widget-components/basicComponents';
import { SearchCloseIcon } from './SearchCloseIcon';
import { StyledButton } from 'client/widget-components/basicComponents/StyledButton';

interface SearchInputProps {
    inputRef: React.RefObject<HTMLInputElement> | null;
    value: string;
    placeholder?: string;
    styles?: {
        input?: ResponsiveStylesProp;
        inputFocus?: ResponsiveStylesProp;
    };
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onFocus?: () => void;
    onClose: () => void;
}

export const SearchInput: FC<SearchInputProps> = ({
    inputRef,
    value,
    placeholder,
    styles,
    onChange,
    onFocus,
    onClose,
}) => {
    const [isTabPressed, setIsTabPressed] = useState(false);

    return (
        <Div
            styles={defaultInputWrapperStyles}
            onKeyDown={(event) => {
                if (event.key === 'Tab') {
                    setIsTabPressed(true);
                }
            }}
            onMouseDown={() => setIsTabPressed(false)}
        >
            <Input
                ref={inputRef}
                styles={[
                    defaultInputStyles,
                    isTabPressed ? {} : mouseInputStyles,
                    styles?.input,
                    nestResponsiveStyles(
                        mergeResponsiveStyles(styles?.inputFocus),
                        {
                            pseudoSelector: 'focus',
                        }
                    ),
                ]}
                data-grab='collection-search-widget-input'
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                onFocus={onFocus}
                increaseSpecificity
                role='search'
                aria-label='search products'
                data-auto='collection-search-input'
            />

            <StyledButton
                styles={defaultCloseBtnStyles}
                onClick={onClose}
                data-auto='search-popup-close-btn'
            >
                <SearchCloseIcon />
            </StyledButton>
        </Div>
    );
};

const defaultInputWrapperStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        alignItems: 'center',
        padding: 40,
    },
    [RuntimeMediaQuery.MOBILE]: {
        padding: 20,
    },
};

const defaultInputStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'block',
        width: '100%',
        padding: '5px',
    },
};

const mouseInputStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        outline: 'none',
    },
};

const defaultCloseBtnStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        color: '#313131',
        marginInlineStart: '15px',
    },
};
