import { WrapDirection } from '../sliderCommonTypes';
type CalculatePositionsArgs = {
    wrapDirection: WrapDirection;
    numberOfSlides: number;
    currentSlideIndex: number;
    lastSlideIndex: number;
    slidesInFrame: number;
};
type CalculatePositionsReturn = { startPosition: string; endPosition: string };
const calculatePositions = ({
    wrapDirection,
    numberOfSlides,
    currentSlideIndex,
    lastSlideIndex,
    slidesInFrame,
}: CalculatePositionsArgs): CalculatePositionsReturn => {
    let startPosition = '';
    let endPosition = '';

    if (wrapDirection === 'toLast') {
        startPosition = `-0%`;
        endPosition = `-${(numberOfSlides + 1) * 100}%`;
    } else if (wrapDirection === 'toFirst') {
        startPosition = `-${(numberOfSlides + 1) * 100}%`;
        endPosition = `0%`;
    } else {
        startPosition = `-${(currentSlideIndex * 100) / slidesInFrame}%`;
        endPosition = `-${(lastSlideIndex * 100) / slidesInFrame}%`;
    }

    return { startPosition, endPosition };
};
export default calculatePositions;
