import styled from 'styled-components';
import React, { FC } from 'react';
import { LinkFunctionalityDomAttributes } from 'client/widget-components/CommonModelTypes';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { toCSSObject } from 'client/widget-components/responsive-styles/toCSSObject';

interface Props {
    linkFunctionalityDomAttributes?: LinkFunctionalityDomAttributes;
    styles?: ResponsiveStylesProp;
    children: React.ReactNode;
    classNames?: string[];
    grabId?: string;
}

/**
 *
 * This is the runtime counterpart of the Editor linkPicker. LinkPicker returns domAttributes, and LinkFunctionalityWrapper spreads them to the dom.
 */
const LinkFunctionalityWrapper: FC<Props> = ({
    linkFunctionalityDomAttributes,
    grabId,
    classNames,
    ...otherProps
}) => {
    return (
        <Anchor
            {...linkFunctionalityDomAttributes}
            data-auto={grabId}
            data-grab={grabId}
            className={classNames?.join(' ')}
            {...otherProps}
        />
    );
};

export default LinkFunctionalityWrapper;

interface AnchorProps {
    styles?: ResponsiveStylesProp;
}

const Anchor = styled.a.withConfig({
    shouldForwardProp: (key) => (key as any) !== 'styles',
})<AnchorProps>(({ styles }) => toCSSObject(styles, true));
