import React, { FC, useMemo } from 'react';
import { Breadcrumbs } from 'client/widget-components';
import {
    BreadcrumbLinkData,
    SeparatorType,
} from 'client/widget-components/components/Breadcrumbs';
import {
    upgradeStyles,
    DeprecatedWidgetStyles,
} from 'client/widget-components/responsive-styles/deprecated/styled-components-utils';
import { context } from '@shared-services/context-service';
import withErrorBoundary from 'client/widget-components/components/ErrorBoundary/withErrorBoundary';
import { RuntimeWidget } from 'client/types';
import { localizationService } from '@shared-services/localization-service';

export interface RuntimeBreadcrumbsProps {
    links: BreadcrumbLinkData[];
    _styles?: {
        root?: React.CSSProperties;
        links?: React.CSSProperties;
        linksHover?: React.CSSProperties;
        unlinkableItems?: React.CSSProperties;
        currentItem?: React.CSSProperties;
        separator?: React.CSSProperties;
    };
    separatorType?: SeparatorType;
    addSchemaMarkup: boolean;
    emptyMessage?: string;
}

const RuntimeBreadcrumbs: FC<RuntimeBreadcrumbsProps> = (props) => {
    const schemaMarkup = useMemo(() => {
        const schemaObj = {
            '@context': 'http://schema.org/',
            '@type': 'BreadcrumbList',
            itemListElement: props.links
                .filter(
                    (item) => item?.linkProps && item?.linkProps.href !== '#'
                )
                .map((item, index) => {
                    return {
                        '@type': 'ListItem',
                        position: index + 1,
                        item: {
                            name: item.title,
                            ...(!item?.linkProps?.currentPage && {
                                id: item?.linkProps?.href,
                            }),
                        },
                    };
                }),
        };
        return JSON.stringify(schemaObj);
    }, [props]);

    const upgradedStyles = useMemo(() => {
        return upgradeStyles(props._styles as DeprecatedWidgetStyles);
    }, [props._styles]);
    return (
        <div data-auto='runtime-breadcrumbs-widget'>
            {props.addSchemaMarkup && props.links.length > 1 ? (
                <script
                    data-auto='schema'
                    type='application/ld+json'
                    dangerouslySetInnerHTML={{ __html: schemaMarkup }}
                ></script>
            ) : null}
            <Breadcrumbs
                styles={upgradedStyles.rules?.root}
                linksStyles={upgradedStyles.rules?.links}
                linksHoverStyles={upgradedStyles.rules?.linksHover}
                unlinkableItemsStyles={upgradedStyles.rules?.unlinkableItems}
                currentItemStyles={upgradedStyles.rules?.currentItem}
                separatorStyles={upgradedStyles.rules?.separator}
                separatorType={props?.separatorType}
                links={props.links}
                showEmptyMessage={context.isEditor}
                emptyMessage={localizationService.str(
                    'ui.ed.breadcrumbs.empty.message'
                )}
            />
        </div>
    );
};

const BoundedBreadcrumbs = withErrorBoundary({
    Comp: RuntimeBreadcrumbs,
    componentName: 'RuntimeBreadcrumbs',
});

const RuntimeBreadcrumbsWidget: RuntimeWidget<RuntimeBreadcrumbsProps> = (
    props
) => <BoundedBreadcrumbs {...props} />;

RuntimeBreadcrumbsWidget.skipHydration = true;

export default RuntimeBreadcrumbsWidget;
